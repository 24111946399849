/**
 * 登录弹窗多语言
 */
export const lang = {
  cn: {
    'login.description': '欢迎使用TokTV',
    'login.tip.term': '继续操作即表示您同意本条款和隐私政策',
    'login.btn.google': 'Google登录',
    'login.btn.facebook': 'Facebook登录',
    'login.btn.loading': '登录中...',
    'login.name.default': '欢迎登录',
    'login.google.label': '登录后解锁更多剧集',
    'login.share.txt1': '您即将进入Toktv Creator Marketplace，一个分享短剧的平台。',
    'login.share.txt2': '分享并赚钱！',
    'login.share.txt3.lf': '每月最高可赚',
    'login.share.txt3.rt': '！！！',
    'login.suggestion': '以下是您的toktv账号密码，建议截图保存或复制保存。',
    'login.login': '登录',
    'login.login.other': '第三方登录',
    'login.account.placeholder': 'Toktv(非Google、非Facebook)账号',
    'login.mm.placeholder': '请输入密码',
    'login.account': '账号',
    'login.mm': '密码',
    'login.copy': '复制账号密码',
    'login.ok': '免费试用',
    'login.ok2': '我知道了',
    'login.ok.share': '去分享',
    'login.bind.title': '第三方绑定',
    'login.bind.popup.title': '温馨提示',
    'login.bind.warn.txt1': '您的{platform}账号',
    'login.bind.warn.txt2': '昵称[{nickName}]已注册',
    'login.bind.warn.txt3': '是否切换到本账号直接登录？',
    'login.bind.st2': '该账号已被其他TokTV绑定，为了您的账户安全，请切换其他账号绑定',
    'login.bind.ok': '确认',
    'login.bind.cancel': '取消',
    'login.bind.to': '去绑定',
    'login.copy.content':
      '感谢申请Toktv账户，请牢记账号或妥善保管，我们的域名：https://m.toktv.cc/  【您的账户】{account}  【账密密码】{mm}',
  },
  cnt: {
    'login.description': '歡迎使用 TokTV',
    'login.tip.term': '繼續操作即表示您同意本條款和隱私政策',
    'login.btn.google': 'Google登錄',
    'login.btn.facebook': 'Facebook登錄',
    'login.btn.loading': '登錄中...',
    'login.name.default': '歡迎登入',
    'login.google.label': '登入後解鎖更多劇集',
    'login.share.txt1': '您即將進入Toktv Creator Marketplace，一個分享短劇的平台。',
    'login.share.txt2': '分享並賺钱！',
    'login.share.txt3.lf': '每月最高可賺',
    'login.share.txt3.rt': '！！！',
    'login.suggestion': '以下是您的toktv帳戶憑證，建議截圖或複製保存。',
    'login.login': '登錄',
    'login.login.other': '第三方登錄',
    'login.account.placeholder': 'Toktv(非Google、非Facebook)賬戶',
    'login.mm.placeholder': '請輸入密碼',
    'login.account': '帳號',
    'login.mm': '密碼',
    'login.copy': '複製帳號密碼',
    'login.ok': '免費試用',
    'login.ok2': '我知道了',
    'login.ok.share': '去分享',
    'login.bind.title': '第三方綁定',
    'login.bind.popup.title': '溫馨提示',
    'login.bind.warn.txt1': '您的{platform}帳號',
    'login.bind.warn.txt2': '暱稱[{nickName}]已註冊',
    'login.bind.warn.txt3': '是否切換到本帳號直接登錄？',
    'login.bind.st2': '此帳號已與其他 TokTV 綁定，為了您的帳戶安全，請切換其他帳號綁定。',
    'login.bind.ok': '確認',
    'login.bind.cancel': '取消',
    'login.bind.to': '去綁定',
    'login.copy.content':
      '謝謝您申請 Toktv 帳戶。請記住或安全保管您的帳戶詳細信息。我們的域名是：https://m.toktv.cc/ 【您的帳戶】{account}  【帳戶密碼】{mm}',
  },
  en: {
    'login.description': 'Welcome to TokTV',
    'login.tip.term': 'Continuing indicates agreement with our terms and privacy policy',
    'login.btn.google': 'Login with Google',
    'login.btn.facebook': 'Login with Facebook',
    'login.btn.loading': 'Be logging in...',
    'login.name.default': 'Login please',
    'login.google.label': 'Login to unlock more episodes',
    'login.share.txt1':
      'You are about to enter Toktv Creator Marketplace, a platform for sharing short dramas.',
    'login.share.txt2': 'Share and earn!',
    'login.share.txt3.lf': 'Making Up To',
    'login.share.txt3.rt': 'Per Month!!!',
    'login.suggestion':
      'Below are your toktv account credentials. It is recommended to take a screenshot or copy and save them for future reference. ',
    'login.login': 'Login',
    'login.login.other': 'Third-party login',
    'login.account.placeholder': 'Toktv (Non-Google, Non-Facebook) Account',
    'login.mm.placeholder': 'Please enter password',
    'login.account': 'Account',
    'login.mm': 'Password',
    'login.copy': 'Copy Account and Password',
    'login.ok': 'Free trial',
    'login.ok2': 'Got it',
    'login.ok.share': 'Go Share',
    'login.bind.title': 'Third-party Binding',
    'login.bind.popup.title': 'Kind tips',
    'login.bind.warn.txt1': 'Your {platform} account',
    'login.bind.warn.txt2': 'Nickname [{nickName}] registered',
    'login.bind.warn.txt3': 'Would you like to switch to this account and log in directly?',
    'login.bind.st2':
      'This account has been bound to another TokTV account. For the safety of your account, please switch to another account binding.',
    'login.bind.ok': 'Confirm',
    'login.bind.cancel': 'Cancel',
    'login.bind.to': 'Go to bind',
    'login.copy.content':
      'Thank you for applying for a Toktv account. Please remember or securely store your account details. Our domain is: https://m.toktv.cc/  【Your account】{account}   【Account password】{mm}',
  },
  pt: {
    'login.description': 'Bem-vindo ao TokTV',
    'login.tip.term': 'Continuar indica que você concorda com os termos e política de privacidade',
    'login.btn.google': 'Entrar com o Google',
    'login.btn.facebook': 'Entrar com o Facebook',
    'login.btn.loading': 'Iniciando sessão...',
    'login.name.default': 'Faça login pfv.',
    'login.google.label': 'Faça login para desbloquear eps',
    'login.share.txt1':
      'Você está prestes a entrar no Toktv Creator Marketplace, uma plataforma para compartilhar curtas-metragens.',
    'login.share.txt2': 'Compartilhe e ganhe!',
    'login.share.txt3.lf': 'Ganhe até',
    'login.share.txt3.rt': 'por mês!!!',
    'login.suggestion':
      'Aqui estão as credenciais da sua conta no toktv. Recomenda-se tirar um screenshot ou copiá-las e guardá-las para referência futura.',
    'login.login': 'Login',
    'login.login.other': 'Login de terceiros',
    'login.account.placeholder': 'Conta Toktv (Não-Google, Não-Facebook)',
    'login.mm.placeholder': 'Por favor, insira a senha',
    'login.account': 'Conta',
    'login.mm': 'Senha',
    'login.copy': 'Copiar Conta e Senha',
    'login.ok': 'Teste gratuito',
    'login.ok2': 'Entendi',
    'login.ok.share': 'Compartilhar',
    'login.bind.title': 'Ligação de Terceira Parte',
    'login.bind.popup.title': 'Dicas gentis',
    'login.bind.warn.txt1': 'Sua conta {platform}',
    'login.bind.warn.txt2': 'O apelido [{nickName}] foi registrado',
    'login.bind.warn.txt3': 'Deseja mudar para esta conta e fazer login diretamente?',
    'login.bind.st2':
      'Esta conta foi ligada a outra conta TokTV. Para a segurança da sua conta, por favor mude a ligação da conta.',
    'login.bind.ok': 'Confirmar',
    'login.bind.cancel': 'Cancelar',
    'login.bind.to': 'vincular',
    'login.copy.content':
      'Obrigado por solicitar uma conta no Toktv. Por favor, lembre-se ou guarde com segurança os detalhes da sua conta. Nosso domínio é: https://m.toktv.cc/    【Sua conta】{account}   【Senha da conta】{mm}',
  },
  th: {
    'login.description': 'เข้าสู่ระบบเพื่อประสบการณ์ที่ดีขึ้น',
    'login.tip.term': 'ดำเนินการต่อแสดงว่าคุณยอมรับข้อกำหนดและนโยบายความเป็นส่วนตัว',
    'login.btn.google': 'เข้าสู่ระบบด้วย Google',
    'login.btn.facebook': 'เข้าสู่ระบบด้วย Facebook',
    'login.btn.loading': 'กำลังเข้าสู่ระบบ ...',
    'login.name.default': 'ยินดีต้อนรับสู่ระบบ',
    'login.google.label': 'ลงชื่อเข้าใช้เพื่อปลดล็อคตอนอื่นๆ',
    'login.share.txt1':
      'คุณกำลังจะเข้าสู่ Toktv Creator Marketplace แพลตฟอร์มสำหรับแบ่งปันละครสั้น',
    'login.share.txt2': 'แชร์และรับรายได้!',
    'login.share.txt3.lf': 'ทำได้สูงสุด',
    'login.share.txt3.rt': 'ต่อเดือน!!!',
    'login.suggestion':
      'นี่คือข้อมูลการเข้าใช้บัญชี toktv ของคุณค่ะ/ครับ คำแนะนำคือ ให้ถ่ายภาพหน้าจอหรือคัดลอกเก็บไว้เพื่ออ้างอิงในอนาคต',
    'login.login': 'เข้าสู่ระบบ',
    'login.login.other': 'เข้าสู่ระบบบุคคลที่สาม',
    'login.account.placeholder': 'บัญชี Toktv (ไม่ใช่ของ Google หรือ Facebook)',
    'login.mm.placeholder': 'กรุณาใส่รหัสผ่าน',
    'login.account': 'บัญชี',
    'login.mm': 'รหัสผ่าน',
    'login.copy': 'คัดลอกบัญชีและรหัสผ่าน',
    'login.ok': 'ทดลองใช้ฟรี',
    'login.ok2': 'เข้าใจแล้ว',
    'login.ok.share': 'แบ่งปัน',
    'login.bind.title': 'ผูกข้อมูลกับบุคคลที่สาม',
    'login.bind.popup.title': 'เคล็ดลับดีๆ',
    'login.bind.warn.txt1': 'บัญชี {platform} ของคุณ',
    'login.bind.warn.txt2': 'ชื่อเล่น [{nickName}] ได้รับการลงทะเบียนแล้ว',
    'login.bind.warn.txt3': 'คุณต้องการสลับไปใช้บัญชีนี้และเข้าสู่ระบบโดยตรงหรือไม่?',
    'login.bind.st2':
      'บัญชีนี้ได้ผูกกับบัญชี TokTV อื่นแล้ว สำหรับความปลอดภัยของบัญชีของคุณ โปรดเปลี่ยนการผูกบัญชี',
    'login.bind.ok': 'ยืนยัน',
    'login.bind.cancel': 'ยกเลิก',
    'login.bind.to': 'ไปที่การผูก',
    'login.copy.content':
      'ขอบคุณสำหรับการสมัครบัญชี Toktv โปรดจำหรือเก็บข้อมูลบัญชีของคุณไว้ให้ดี โดเมนของเราคือ: https://m.toktv.cc/   【บัญชีของคุณ】{account}   【รหัสผ่านบัญชี】{mm}',
  },
  es: {
    'login.description': 'Bienvenido a TokTV',
    'login.tip.term': 'Continuar implica aceptar estos términos y la política de privacidad',
    'login.btn.google': 'Iniciar sesión con Google',
    'login.btn.facebook': 'Iniciar sesión con Facebook',
    'login.btn.loading': 'Iniciando sesión...',
    'login.name.default': 'Bienvenido a iniciar sesión',
    'login.google.label': 'Desbloquea más episodios después de iniciar sesión',
    'login.share.txt1':
      'Está a punto de ingresar a Toktv Creator Marketplace, una plataforma para compartir cortometrajes.',
    'login.share.txt2': '¡Comparte y gana',
    'login.share.txt3.lf': '¡¡¡Gana hasta',
    'login.share.txt3.rt': 'al mes',
    'login.suggestion':
      'A continuación se encuentran las credenciales de tu cuenta de toktv. Se recomienda tomar una captura de pantalla o copiarlas y guardarlas para referencia futura.',
    'login.login': 'Iniciar sesión',
    'login.login.other': 'Iniciar sesión con una cuenta de terceros',
    'login.account.placeholder': 'Cuenta TokTV (no Google, no Facebook)',
    'login.mm.placeholder': 'Ingrese su contraseña',
    'login.account': 'Cuenta',
    'login.mm': 'Contraseña',
    'login.copy': 'Copiar cuenta y contraseña',
    'login.ok': 'Prueba gratis',
    'login.ok2': 'Entendido',
    'login.ok.share': 'para compartir',
    'login.bind.title': 'Vincular cuenta de terceros',
    'login.bind.popup.title': 'Aviso',
    'login.bind.warn.txt1': 'Su cuenta de {platform}',
    'login.bind.warn.txt2': 'El apodo [{nickName}] ya está registrado',
    'login.bind.warn.txt3': '¿Desea cambiar a esta cuenta y iniciar sesión directamente?',
    'login.bind.st2':
      'Esta cuenta ya está vinculada a otro TokTV. Para su seguridad, por favor use otra cuenta para vincular',
    'login.bind.ok': 'Confirmar',
    'login.bind.cancel': 'Cancelar',
    'login.bind.to': 'Vincular',
    'login.copy.content':
      'Gracias por registrar una cuenta TokTV, por favor recuerde su cuenta o guárdela bien. Nuestro dominio: https://m.toktv.cc/  【Su cuenta】{account}  【Contraseña】{mm}',
  },
} as { [key: string]: any };
