<template>
  <div class="guide">
    <van-popup v-model:show="showGuide" position="bottom" @close="handleCloseGuide">
      <div class="guide__main" :class="{ 'guide-safari': guideType == 1 }">
        <img src="./img/close.png" class="close" @click="handleCloseGuide" />
        <div v-if="guideType == 1">
          <div class="content">
            <div class="header">
              <div class="img"><img :src="pngLogo" alt="" /></div>
              <div class="txt">
                <h3 class="title">Quickly add desktop apps</h3>
                <p class="tips">Easy startup·Full screen experience</p>
              </div>
            </div>
            <div class="default" v-if="!showSwipe">
              <img :src="langIsZh ? guideDeskTopDefaultZh : guideDeskTopDefaultEn" />
            </div>
            <div class="swiper" v-else>
              <img :src="langIsZh ? guideDeskTopGifZh : guideDeskTopGifEn" />
            </div>
          </div>
        </div>
        <div v-else>
          <div class="top">
            <div class="swiper">
              <img :src="langIsZh ? guideSafariGifZh : guideSafariGifEn" />
            </div>
          </div>
          <div class="bottom">
            <div class="safari">
              Please use<img src="./img/safari.png" /><span>Safari</span>your browser to open the
              link
            </div>
            <div class="logo">
              Install<img src="./img/logo.png" /><span>Toktv</span>Experience it on your desktop
            </div>
            <div class="copy" @click="handleCopyEvent">copy Link</div>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="preload-gift" style="display: none">
      <img :src="guideDeskTopDefaultEn" />
      <img :src="guideDeskTopDefaultZh" />
      <img :src="guideDeskTopGifEn" v-if="!isSafari" />
      <img :src="guideDeskTopGifZh" v-if="!isSafari" />
      <img :src="guideSafariGifEn" v-if="isSafari" />
      <img :src="guideSafariGifZh" v-if="isSafari" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import guideDeskTopDefaultEn from './desktop/en/default.png';
import guideDeskTopDefaultZh from './desktop/zh/default.png';
import guideDeskTopGifEn from './desktop/en/1.gif';
import guideDeskTopGifZh from './desktop/zh/1.gif';
import guideSafariGifEn from './safari/en/1.gif';
import guideSafariGifZh from './safari/zh/1.gif';
import pngLogo from './img/logo1.png';
import { useCopy } from '@/composables/useCopy';
import { getCurrentLang, getHrefWithoutMyExtraInfo } from '@/utils';
import { isSafari } from '@/utils/is';

const showGuide = ref(false);
const showSwipe = ref(false);
const guideType = ref(isSafari ? 1 : 2);
const defaultLocale = getCurrentLang();
const langIsZh = computed(() => {
  return defaultLocale.indexOf('zh') > -1;
});

const emit = defineEmits(['hide']);

const props = defineProps({
  show: Boolean,
});

watch(
  () => props.show,
  (newVal) => {
    showGuide.value = newVal;
    if (newVal) {
      setTimeout(() => {
        showSwipe.value = true;
      }, 1200);
    }
  }
);

const handleCloseGuide = () => {
  emit('hide');
  showSwipe.value = false;
};

const handleCopyEvent = () => {
  useCopy(getHrefWithoutMyExtraInfo());
};
</script>
<style lang="scss" scoped>
.guide {
  .guide__main {
    position: relative;
    width: 351px;
    height: 347px;
    margin: 0 auto 14px;
    overflow: hidden;
    background: #fff;
    border-radius: 20px;

    .close {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 9;
      width: 23px;
      height: 23px;
    }

    .top {
      position: relative;
      width: 100%;
      height: 184px;

      .default,
      .swiper {
        &.swiper {
          .label {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 9;
            min-width: 50px;
            max-width: max-content;
            height: 21px;
            padding: 0 10px;
            color: #fff;
            font-weight: 500;
            font-size: 11px;
            line-height: 21px;
            text-align: center;
            background: linear-gradient(180deg, #10ce87 0%, #0bd1ad 100%);
            border-radius: 20px 0 14px;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      height: 163px;
      padding-top: 20px;

      .safari,
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #222;
        font-size: 14px;

        &.safari {
          margin-bottom: 10px;

          img {
            width: 21px;
            height: 21px;
          }

          span {
            color: #2c7bd0;
          }
        }

        &.logo {
          img {
            width: 18px;
            height: 18px;
          }

          span {
            color: #ff3968;
            font-weight: bold;
            font-size: 14px;
          }
        }

        img {
          margin: 0 5px;
        }

        span {
          margin-right: 5px;
        }
      }

      .copy {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 305px;
        height: 48px;
        margin: 24px auto 0;
        color: #fff;
        font-size: 16px;
        background: #12cdb0;
        border-radius: 10px;
      }
    }

    .content {
      position: relative;
      padding: 24px 24px 30px;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .img {
          width: 40px;
          height: 40px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .txt {
          .title {
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;
          }

          .tips {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .swipe {
        position: relative;
        height: 184px;
        overflow: hidden;
        background: #fff;
        border-radius: 20px;

        .label {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          min-width: 50px;
          max-width: max-content;
          height: 21px;
          padding: 0 10px;
          color: #fff;
          font-weight: 500;
          font-size: 11px;
          line-height: 21px;
          text-align: center;
          background: linear-gradient(180deg, #10ce87 0%, #0bd1ad 100%);
          border-radius: 20px 0 14px;
        }
      }
    }
  }

  .guide-safari {
    height: 280px;
  }
}
</style>
