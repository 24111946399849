import { lang } from './../components/login/lang';

/**
 * lang: 西班牙语
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': 'Por favor inicie sesión',
  'common.toast.error.network': 'Error de red, por favor intente de nuevo',
  'common.toast.error.params': 'Error de parámetros',
  'common.btn.drama.watching': 'Ver series',
  'common.list.finished': 'No hay más',
  'common.btn.back.home': 'Volver a la página principal',
  'common.text.text1': 'Desliza hacia arriba para ver el siguiente episodio',

  // 剧集相关
  'drama.episode': 'Episodio {count}',
  'drama.watch.episode': 'Visto hasta el episodio {count}',
  'drama.total.episodes': 'Total de {count} episodios',
  'drama.selections': 'Seleccionar episodio',
  'drama.lock.toast': '¡La historia intermedia también es muy interesante, no te la pierdas!',

  // 登录
  ...lang.es,

  // 首页
  'home.nav.record': 'Historial de visualización',
  'home.nav.logout': 'Cerrar sesión',
  'home.nav.vip': 'Centro VIP',
  'home.btn.download': 'Descargar APP',
  'home.btn.open': 'Abrir APP y ver tus videos favoritos',
  'home.tabs.tab1': 'Popular',
  'home.tabs.tab2': 'Descubrir',
  'home.tabs.tab3': 'Recomendado',
  'home.tabs.tab4': 'Inicio',
  'home.tabs.tab5': 'Series',
  'home.tabs.tab6': 'Mi cuenta',
  'home.tabs.tab7': 'Historial',
  'home.tabs.tab8': 'Beneficios',

  // 观看历史
  'record.title': 'Historial de visualización',
  'record.title.share': 'Compartir',
  'record.btn.share': 'Compartir',
  'record.btn.playing': 'Continuar viendo',
  'record.toast.copied': 'Copiado con éxito',
  'record.empty': 'No hay historial de visualización',

  // 分享页面
  'share.description': 'Abre TokTV para ver el video completo.',
  'share.btn.open': 'Abrir',
  'share.facebook.title':
    'Mira dramas cortos en alta definición en TokTV, incluyendo modernos, CEO, venganza, thriller, viajes en el tiempo, comedia y otros géneros populares. Elige el que más te guste. Mejora tu experiencia de entretenimiento con TokTV. Sitio web: https://m.toktv.cc',

  // 播放页面
  'video.play': 'Reproducir',
  'video.tips': 'Si la reproducción es lenta, ve a la APP para ver',
  'video.select': 'Seleccionar episodio',
  'video.nomore': 'No hay más',
  'video.nolog': 'No hay historial de series vistas',
  'video.collect': 'Favoritos',
  'video.havcollected': 'Añadido a favoritos',
  'video.title1': 'Nuevos lanzamientos',
  'video.title2': 'En tendencia',
  'video.title3': 'Te podría gustar',
  'video.guess.like': 'Al 90% de las personas les gusta',

  'video.wp.title': 'Aviso Dramático',
  'video.wp.desc1': 'Muy recomendable recibir notificaciones de episodios interesantes de Toktv!!!',
  'video.wp.desc2':
    'Si cancela, no recibirá notificaciones diarias de nuevos dramas, episodios emocionantes y beneficios especiales.',
  'video.wp.btn.txt': 'Permitir',

  // 下载页面
  'download.btn': 'Descargar ahora',
  'download.btn1': 'Instalar con un clic y desbloquear dramas cortos emocionantes',
  'download.btn2': 'Instalando',
  'download.btn3': 'Abrir ahora',

  // 会员中心
  'play.txt.kb': 'Moneda K',
  'play.txt.account': 'Cuenta',
  'play.txt.normal': 'Normal',
  'play.txt.recharge': 'Recargar',
  'play.txt.time': 'El VIP expira el {time}',
  'play.item.vip': 'Recargar VIP',
  'play.item.recharge': 'Recargar Moneda K',
  'play.item.details': 'Detalles de Moneda K',
  'play.item.gold': 'Detalles de Moneda de oro',
  'play.txt.details': 'No hay detalles de Moneda K',
  'play.txt.no_recharge': 'No hay historial de recarga',
  'play.txt.no_gold': 'No hay historial de Moneda de oro',
  'play.txt.purchase_time': 'Hora de compra',
  'play.txt.order': 'Número de pedido',
  'play.txt.order_err': 'Error en el procesamiento del pedido, por favor intente de nuevo',
  'play.txt.status_err': 'Pago fallido',
  'play.txt.status_suc': 'Pago exitoso',
  'play.txt.balance': 'Saldo de Moneda K',
  'play.txt.balance1': 'Saldo',
  'play.txt.episode.k': 'Este episodio',
  'play.txt.episode.v': '50 monedas k o bonificación',
  'play.txt.more': 'No hay más',
  'play.txt.bonus': 'Recarga con bonificación',
  'play.txt.tips': 'Saldo insuficiente de Moneda K, por favor recargue',
  'play.txt.wallet': 'Mi billetera',
  'play.txt.desc': 'Disfruta de todos los dramas cortos de forma gratuita',
  'play.txt.to-bind': 'Vincular cuenta',
  'play.txt.unlockall': 'Desbloquear todos los episodios',
  'play.txt.limitpay': 'Oferta por tiempo limitado',

  // 充值
  'play.txt.pay': 'Pagar ahora',
  'play.txt.rurchase': 'Restaurar compra',
  'play.txt.pack': 'Paquete VIP',
  'play.get.coins': 'Recibe {coins} Monedas K',
  'play.txt.notice': 'Aviso',
  'play.txt.btn1': 'Pago completado',
  'play.txt.btn2': 'Problema con el pago, reintentar',
  'play.txt.btn3': 'Desbloquear',
  'play.txt.desc1':
    '1. La moneda virtual y los derechos adquiridos pertenecen al servicio en línea y no son reembolsables después de la compra;',
  'play.txt.desc2':
    '2. Si ha comprado un servicio de suscripción automática, para proteger sus derechos, necesita abrir la aplicación Google Play para cancelar la suscripción manualmente;',
  'play.txt.desc3':
    '2. Si la recarga fue exitosa, pero el saldo no cambió, haga clic en "Restaurar compra" para actualizar;',
  'play.txt.desc4': '3. Si tiene otros problemas, contacte con el servicio al cliente.',
  'play.txt.desc5': 'Confirme si el pago de {type} se ha completado',
  'play.txt.desc6': '¿Necesitas ayuda? Consulte nuestro centro de ayuda o contáctenos',
  'play.txt.desc7':
    'Para proteger sus activos digitales en TokTV, le recomendamos utilizar su cuenta de Facebook o Google para comprar Monedas K',

  //设置
  'setting.title': 'Configuración',
  'setting.menu1': 'Cambiar idioma',
  'setting.menu2': 'Términos de servicio',
  'setting.menu3': 'Política de privacidad',
  'setting.menu4': 'Contáctenos',
  'setting.menu4.desc1': 'Correo electrónico de atención al cliente',
  'setting.menu4.desc2': 'Responderemos su correo lo antes posible',

  //消息
  'message.desc1': 'Error en la solicitud, haga clic para recargar',
  'message.desc2': 'Recarga exitosa',
  'message.desc3': 'Instalación exitosa',

  //获得弹框免费解锁
  'free.unlock.text1': 'Felicidades por obtener',
  'free.unlock.text2': 'Desbloquea más episodios gratis',
  'free.unlock.text3': 'Descargar ahora para desbloquear',

  //支付挽留弹框
  'pay.hold.text1': 'Beneficios exclusivos para usuarios beta',
  'pay.hold.text2': 'Oferta limitada',
  'pay.hold.text3': 'Precio de descuento',
  'pay.hold.text4': 'VIP gratis',
  'pay.hold.text5': 'Calidad HD',
  'pay.hold.text6': 'Estatus premium',
  'pay.hold.text7': 'Pagar ahora',
  'pay.hold.text8': 'Acaba de comprar',

  //福利界面
  'task.wallet': 'Mi billetera',
  'task.k': 'Moneda K',
  'task.gold': 'Moneda de oro',
  'task.continuous': 'Registro continuo: {day} días',
  'task.day': 'Día {day}',
  'task.sign.btn': 'Registrarse',
  'task.day.text': 'Tarea diaria',
  'task.newuser.text': 'Tareas para nuevos usuarios',
  'task.lockvideo.text': 'Recompensa por ver series',
  'task.btn.todo': 'Completar',
  'task.btn.Receive': 'Recibir',
  'task.lock.episodes': 'Ver {episodes} episodios',
  'task.signsuc.text': 'Registro exitoso',
  'task.continuous.suc.desc':
    'Complete el registro durante 7 días consecutivos para recibir el doble de recompensas',
  'task.again.get': 'Recibir {gold} monedas de oro de nuevo',
  'task.get.title': 'Felicidades, has recibido',
  'task.scanad.double': 'Ver anuncio para recibir el doble',
  'task.btn.ok': 'Aceptar',
  'task.btn.done': 'Completado',
  'task.btn.comeback': 'Regresar mañana',
  'task.turntable.desc1': 'Ruleta de la suerte 100%',
  'task.turntable.desc2': 'Muchos premios esperan por ti',
  'task.ad': 'Ver anuncio para recibir monedas de oro',
  'task.ad.btn': 'Ver',

  //大转盘界面
  'turntable.title': 'Ruleta de la suerte',
  'turntable.drawnum': 'Quedan {num} oportunidades hoy',
  'turntable.drawbtn': 'Sorteo gratis',
  'turntable.rule': 'Reglas del evento',
  'turntable.rule1':
    '1. Al participar en este evento, aceptas las reglas del evento. Puedes participar en el sorteo {total} veces al día;',
  'turntable.rule2':
    '2. Los usuarios pueden ganar monedas de oro a través del sorteo gratuito. Anuncios aleatorios pueden aparecer durante el evento, completarlos te da oportunidades de sorteo;',
  'turntable.rule3': '3. La interpretación final de este evento corresponde a la empresa',

  //国家/地区
  'region.title': 'País/Región',
  'region.choose.wallet': 'Por favor seleccione una billetera',
  'region.choose.country': 'Por favor seleccione',

  //视频页面支付挽留弹框
  'payhold.video.title': 'Regalo exclusivo',
  'payhold.video.limit': 'Oferta limitada',

  //首页支付挽留弹框
  'payhold.home.title': 'Oferta especial',
  'payhold.home.until': 'Precio reducido hasta',
  'payhold.home.buy': 'Comprar ahora',
  'payhold.home.limit': 'fin de la oferta',
  'payhold.home.bouns': 'Desbloquea más beneficios exclusivos al ser miembro',

  // 活动页面
  'act.title1': 'Ganar dinero en línea',
  'act.desc1': 'Comparte el drama corto y obtendrás el',
  'act.desc2': 'del monto recargado',
  'act.title2': 'Registrarse ahora',
  'act.tg.chat': 'Chat TG',
  'act.tg.channel': 'Canal TG',
  'act.tg.bot': 'Bot TG',
};
