import { lang } from './../components/login/lang';

/**
 * lang: 泰语
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': 'กรุณาเข้าสู่ระบบ',
  'common.toast.error.network': 'มีปัญหาในเครือข่าย โปรดลองใหม่',
  'common.toast.error.params': 'พารามิเตอร์ผิด',
  'common.btn.drama.watching': 'ดูซีรีส์',
  'common.list.finished': 'ไม่มีอีกแล้ว',
  'common.btn.back.home': 'กลับมา',
  'common.text.text1': 'เลื่อนขึ้นเพื่อดูตอนต่อไป',

  // 剧集相关
  'drama.episode': 'ตอนที่ {count}',
  'drama.watch.episode': 'ดูถึงตอนที่ {count}',
  'drama.total.episodes': 'ทั้งหมด {count} ตอน',
  'drama.selections': 'ตอนที่',
  'drama.lock.toast': 'เรื่องราวตรงกลางก็น่าสนใจมากเช่นกัน อย่าพลาด!',

  // 登录
  // 'login.description': 'เข้าสู่ระบบเพื่อประสบการณ์ที่ดีขึ้น',
  // 'login.tip.term': 'ดำเนินการต่อแสดงว่าคุณยอมรับข้อกำหนดและนโยบายความเป็นส่วนตัว',
  // 'login.btn.google': 'เข้าสู่ระบบด้วย Google',
  // 'login.btn.facebook': 'เข้าสู่ระบบด้วย Facebook',
  // 'login.btn.logging': 'กำลังเข้าสู่ระบบ ...',
  // 'login.name.default': 'ยินดีต้อนรับสู่ระบบ',
  // 'login.google.label': 'ลงชื่อเข้าใช้เพื่อปลดล็อคตอนอื่นๆ',
  ...lang.th,

  // 首页
  'home.nav.record': 'ประวัติการดู',
  'home.nav.logout': 'ออกจากระบบ',
  'home.nav.vip': 'ศูนย์ VIP',
  'home.btn.download': 'ดาวน์โหลดแอป',
  'home.btn.open': 'เปิดแอปและดูวิดีโอที่คุณสนใจ',
  'home.tabs.tab1': 'ความร้อน',
  'home.tabs.tab2': 'หน้าหลัก',
  'home.tabs.tab3': 'แนะนำค่ะ',
  'home.tabs.tab4': 'หน้าหลัก',
  'home.tabs.tab5': 'ดูอยู่',
  'home.tabs.tab6': 'ศูนย์ผู้ใช้',
  'home.tabs.tab7': 'บันทึก',
  'home.tabs.tab8': 'สวัสดิการ',

  // 观看历史
  'record.title': 'ประวัติการดู',
  'record.title.share': 'แชร์',
  'record.btn.share': 'แชร์',
  'record.btn.playing': 'เล่นต่อ',
  'record.toast.copied': 'คัดลอกสำเร็จ',
  'record.empty': 'ไม่พบข้อมูล',

  // 分享页面
  'share.description': 'เปิด Toktv เพื่อรับชมวิดีโอเต็มรูปแบบ',
  'share.btn.open': 'เปิด',
  'share.facebook.title':
    'ชมละครสั้นความละเอียดสูงบน TokTv ซึ่งรวมถึงละครสั้นแนวทันสมัย บริหารการ และชนะความโหดร้าย ละครสุดสยอง การเดินทางในเวลา คอมเมดี้ และแนวอื่นๆ ที่เป็นที่นิยมล่าสุด เลือกชนิดที่เข้ากันกับรสชาติของคุณ อัพเกรดประสบการณ์ความบันเทิงของคุณด้วย TokTv เว็บไซต์: https://m.toktv.cc',

  // 播放页面
  'video.play': 'เล่น',
  'video.tips': 'หากค้างให้ไปที่แอปเพื่อดู',
  'video.select': 'ตอน',
  'video.nomore': 'มันจบแล้ว',
  'video.nolog': 'คุณยังไม่ได้ดูวิดีโอเลย',
  'video.collect': 'รายการของฉัน',
  'video.havcollected': 'รายการโปรด',
  'video.title1': 'รุ่นใหม่',
  'video.title2': 'ที่นิยมมาก ที่สุด',
  'video.title3': 'แนะนำเพิ่มเติม',
  'video.guess.like': '90% ของคนชอบดู',

  'video.wp.title': 'ประกาศเกี่ยวกับละคร',
  'video.wp.desc1': 'ขอแนะนำอย่างยิ่งให้รับการแจ้งเตือนตอนที่น่าตื่นเต้นจาก Toktv!!!',
  'video.wp.desc2':
    'หากยกเลิก คุณจะไม่ได้รับการแจ้งเตือนรายวันเกี่ยวกับละครใหม่ ตอนที่น่าตื่นเต้น และสิทธิประโยชน์พิเศษ',
  'video.wp.btn.txt': 'อนุญาต',

  // 下载页面
  'download.btn': 'ดาวน์โหลดแอป',
  'download.btn1': 'ติดตั้งและปลดล็อคละครสั้น',
  'download.btn2': 'กำลังติดตั้ง',
  'download.btn3': 'เปิดเดี๋ยวนี้',

  // 会员中心
  'play.txt.kb': 'เหรียญ',
  'play.txt.account': 'บัญชี',
  'play.txt.normal': 'ปกติ',
  'play.txt.recharge': 'เติมเงิน',
  'play.txt.time': 'VIP จะหมดอายุในวันที่ {time}',
  'play.item.vip': 'เติมเงินสมาชิก',
  'play.item.recharge': 'เติมเหรียญ K',
  'play.item.details': 'รายละเอียดเหรียญ K',
  'play.item.gold': 'รายละเอียดทอง',
  'play.txt.details': 'ไม่มีรายละเอียดเหรียญ K',
  'play.txt.no_recharge': 'ไม่มีประวัติการเติมเงิน',
  'play.txt.no_gold': 'ไม่มีบันทึกเหรียญทอง',
  'play.txt.purchase_time': 'เวลาที่ซื้อ',
  'play.txt.order': 'หมายเลขคำสั่งซื้อ',
  'play.txt.order_err': 'การประมวลผลคำสั่งซื้อล้มเหลว โปรดลองอีกครั้ง',
  'play.txt.status_err': 'การชำระเงินล้มเหลว',
  'play.txt.status_suc': 'การชำระเงินสำเร็จ',
  'play.txt.balance': 'ยอดเหรียญ K',
  'play.txt.balance1': 'ยอดเหรียญ',
  'play.txt.episode.k': 'ตอนนี้',
  'play.txt.episode.v': '50 K เหรียญหรือโบนัส',
  'play.txt.more': 'ไม่มีอีกแล้ว',
  'play.txt.bonus': 'โบนัสมูลค่าที่เก็บไว้',
  'play.txt.tips': 'เหรียญ K ไม่เพียงพอ โปรดเติมเงิน',
  'play.txt.wallet': 'กระเป๋าคุมข้อมูล',
  'play.txt.desc': 'สนุกกับของเล่นทั้งหมดฟรี',
  'play.txt.to-bind': 'ไปผูก',
  'play.txt.unlockall': 'ปลดล็อคชุดเต็ม',
  'play.txt.limitpay': 'ข้อเสนอจำกัดเวลา',

  // 充值
  'play.txt.pay': 'ชำระเงินทันที',
  'play.txt.rurchase': 'คืนการซื้อ',
  'play.txt.pack': 'หีบของขวัญคุณภาพสูง VIP',
  'play.get.coins': 'รับ {coins} เหรียญ K',
  'play.txt.notice': 'คำแนะนำ',
  'play.txt.btn1': 'ชำระเงินเรียบร้อยแล้ว',
  'play.txt.btn2': 'มีปัญหาเรื่องการชำระเงินกรุณาชำระเงินอีกครั้ง',
  'play.txt.btn3': 'ปลดล็อค',
  'play.txt.desc1':
    'สกุลเงินเสมือนและสิทธิ์ที่มีอยู่ในบัญชีนั้นควรเป็นบริการเครือข่ายและไม่สามารถขอคืนได้หลังจากซื้อ;',
  'play.txt.desc2':
    'หากคุณซื้อบริการสมัครสมาชิกอัตโนมัติเพื่อปกป้องสิทธิ์ของคุณคุณต้องเปิดแอปพลิเคชัน Google Play และยกเลิกการสมัครสมาชิกด้วยตนเอง;',
  'play.txt.desc3':
    'หลังจากทำการเติมเงินสำเร็จแต่ยอดเงินไม่เปลี่ยนแปลงโปรดคลิก "คืนการซื้อ" เพื่อรีเฟรช;',
  'play.txt.desc4': 'หากมีปัญหาอื่นๆ กรุณาติดต่อฝ่ายบริการลูกค้า.',
  'play.txt.desc5': 'กรุณายืนยัน{type}ชำระเงินเรียบร้อยแล้วใช่ไหม?',
  'play.txt.desc6': 'ต้องการความช่วยเหลือหรือไม่ โปรดดูที่ศูนย์ช่วยเหลือของเราหรือติดต่อเรา',
  'play.txt.desc7':
    'เพื่อปกป้องทรัพย์สินดิจิทัลของคุณบน TokTV เราขอแนะนำให้คุณใช้บัญชี Facebook หรือ Google ของคุณเพื่อซื้อเหรียญ K',

  //设置
  'setting.title': 'การตั้งค่า',
  'setting.menu1': 'เปลี่ยนภาษา',
  'setting.menu2': 'ข้อตกลงผู้ใช้',
  'setting.menu3': 'นโยบายความเป็นส่วนตัว',
  'setting.menu4': 'ติดต่อเรา',
  'setting.menu4.desc1': 'กล่องจดหมายฝ่ายบริการลูกค้าอย่างเป็นทางการ',
  'setting.menu4.desc2': 'เราจะตอบกลับคุณในไม่ช้าเมื่อได้รับจดหมาย',

  //消息
  'message.desc1': 'คำขอล้มเหลวโปรดลองอีกครั้ง',
  'message.desc2': 'สดชื่น',
  'message.desc3': 'การติดตั้งสำเร็จ',

  //获得弹框免费解锁
  'free.unlock.text1': 'ยินดีด้วย',
  'free.unlock.text2': 'ปลดล็อคตอนเพิ่มเติมฟรี',
  'free.unlock.text3': 'ดาวน์โหลดเพื่อปลดล็อคทันที',

  //支付挽留弹框
  'pay.hold.text1': 'เพื่อประโยชน์ส่วนรวม',
  'pay.hold.text2': 'จำกัดเวลา',
  'pay.hold.text3': 'ส่วนลด',
  'pay.hold.text4': 'VIP ดูฟรี',
  'pay.hold.text5': 'คุณภาพ HD',
  'pay.hold.text6': 'สถานะอันทรงเกียรติ',
  'pay.hold.text7': 'จ่ายตอนนี้',
  'pay.hold.text8': 'เพิ่งซื้อ',

  //福利界面
  'task.wallet': 'กระเป๋าสตางค์ของฉัน',
  'task.k': 'K เหรียญ',
  'task.gold': 'เหรียญทอง',
  'task.continuous': 'การเช็คอินต่อเนื่อง: { day } วัน',
  'task.day': 'วัน { day }',
  'task.sign.btn': 'เช็คอิน',
  'task.day.text': 'งานประจำวัน',
  'task.newuser.text': 'ภารกิจสำหรับมือใหม่',
  'task.lockvideo.text': 'รางวัลชมละคร',
  'task.btn.todo': 'เสร็จสมบูรณ์',
  'task.btn.Receive': 'รับ',
  'task.lock.episodes': 'รับชมละคร { episodes } ตอน',
  'task.signsuc.text': 'เช็คอินสำเร็จ',
  'task.continuous.suc.desc': 'เช็คอินครบ 7 วันติดต่อกัน รับโบนัส 2 เท่า',
  'task.again.get': 'ใน neckline { gold } gold',
  'task.get.title': 'ขอแสดงความยินดีที่คุณได้รับ',
  'task.scanad.double': 'ดูโฆษณาสองเท่า',
  'task.btn.ok': 'โอเค',
  'task.btn.done': 'เสร็จสมบูรณ์',
  'task.btn.comeback': 'กลับมาใหม่พรุ่งนี้',
  'task.turntable.desc1': 'แผ่นเสียงโชคดี 100%',
  'task.turntable.desc2': 'ซูเปอร์โดโกลด์ รอให้คุณมารับ',
  'task.ad': 'ดูเหรียญทองปกโฆษณา',
  'task.ad.btn': 'ดู',

  //大转盘界面
  'turntable.title': 'แผ่นเสียงโชคดี',
  'turntable.drawnum': 'โอกาสที่เหลือของ { num } ในวันนี้',
  'turntable.drawbtn': 'จับสลากฟรี',
  'turntable.rule': 'กฎกิจกรรม',
  'turntable.rule1':
    '1.การเข้าร่วมแคมเปญนี้ถือว่าเห็นด้วยกับกฎของแคมเปญและสามารถเข้าร่วมในการจับสลากได้ { total } ครั้งต่อวัน',
  'turntable.rule2':
    '2.ผู้ใช้สามารถรับเหรียญทองผ่านการจับสลากฟรีและงานโฆษณาจะปรากฏแบบสุ่มในครึ่งทางและสามารถรับโอกาสในการจับสลากหลังจากเสร็จสิ้น',
  'turntable.rule3': '3.สิทธิการตีความขั้นสุดท้ายของกิจกรรมนี้เป็นของบริษัท',

  //国家/地区
  'region.title': 'ประเทศ / ภูมิภาค',
  'region.choose.wallet': 'กรุณาเลือกกระเป๋าสตางค์',
  'region.choose.country': 'กรุณาเลือก',

  //视频页面支付挽留弹框
  'payhold.video.title': 'ของขวัญพิเศษ',
  'payhold.video.limit': 'ข้อเสนอจำกัดเวลา',

  //首页支付挽留弹框
  'payhold.home.title': 'คุณได้รับข้อเสนอสุดพิเศษ',
  'payhold.home.until': 'ลดราคาไปยัง',
  'payhold.home.buy': 'ซื้อทันที',
  'payhold.home.limit': 'จบหลังจาก',
  'payhole.home.bouns': 'ปลดล็อคสิทธิพิเศษเพิ่มเติม',

  // 活动页面
  'act.title1': 'หาเงินออนไลน์',
  'act.desc1': 'แชร์ละครสั้นแล้วคุณจะได้รับ',
  'act.desc2': 'ของจำนวนเงินที่เติม',
  'act.title2': 'สมัครตอนนี้',
  'act.tg.chat': 'แชท TG',
  'act.tg.channel': 'ช่อง TG',
  'act.tg.bot': 'บอท TG',
};
