import { lang } from './../components/login/lang';

/**
 * lang: 简体中文
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': '请登录',
  'common.toast.error.network': '网络异常，请重试',
  'common.toast.error.params': '参数错误',
  'common.btn.drama.watching': '去追剧',
  'common.list.finished': '没有更多了',
  'common.btn.back.home': '返回首页',
  'common.text.text1': '向上滑动观看下一集',

  // 剧集相关
  'drama.episode': '第{count}集',
  'drama.watch.episode': '看到第{count}集',
  'drama.total.episodes': '共{count}集',
  'drama.selections': '选集',
  'drama.lock.toast': '中间的故事也非常有趣，不要错过！',

  // 登录
  // 'login.description': '欢迎使用TokTV',
  // 'login.tip.term': '继续操作即表示您同意本条款和隐私政策',
  // 'login.btn.google': 'Google登录',
  // 'login.btn.facebook': 'Facebook登录',
  // 'login.btn.logging': '登录中...',
  // 'login.name.default': '欢迎登录',
  // 'login.google.label': '登录后解锁更多剧集',
  ...lang.cn,

  // 首页
  'home.nav.record': '观看历史',
  'home.nav.logout': '退出登录',
  'home.nav.vip': 'VIP中心',
  'home.btn.download': '下载APP',
  'home.btn.open': '打开APP，看你感兴趣的视频',
  'home.tabs.tab1': '热门',
  'home.tabs.tab2': '发现',
  'home.tabs.tab3': '推荐',
  'home.tabs.tab4': '首页',
  'home.tabs.tab5': '追剧',
  'home.tabs.tab6': '我的',
  'home.tabs.tab7': '历史',
  'home.tabs.tab8': '福利',

  // 观看历史
  'record.title': '观看历史',
  'record.title.share': '分享',
  'record.btn.share': '分享',
  'record.btn.playing': '继续播放',
  'record.toast.copied': '复制成功',
  'record.empty': '暂无观看记录',

  // 分享页面
  'share.description': '打开Toktv观看完整视频。',
  'share.btn.open': '打开',
  'share.facebook.title':
    '在TokTv上观看高清短剧，涵盖现代、总裁、复仇、惊悚、穿越、喜剧和其他最新热门类型的短剧。选择适合您口味的类型。使用TokTv升级您的娱乐体验。网址：https://m.toktv.cc',

  // 播放页面
  'video.play': '播放',
  'video.tips': '若播放卡顿，前往APP观看',
  'video.select': '选集',
  'video.nomore': '已经到底了',
  'video.nolog': '暂无追剧记录',
  'video.collect': '收藏',
  'video.havcollected': '已收藏',
  'video.title1': '新片上架',
  'video.title2': '现在热播',
  'video.title3': '猜你喜欢',
  'video.guess.like': '90%的人都爱看',

  'video.wp.title': '剧集预告',
  'video.wp.desc1': '强烈推荐接收来自Toktv的精彩剧集通知！！！',
  'video.wp.desc2': '如果您取消，您将不会收到新剧、精彩剧集和特殊福利的每日通知。',
  'video.wp.btn.txt': '允许',

  // 下载页面
  'download.btn': '立即下载',
  'download.btn1': '一键安装，解锁精彩短剧',
  'download.btn2': '正在安装',
  'download.btn3': '立即打开',

  // 会员中心
  'play.txt.kb': 'K币',
  'play.txt.account': '账户',
  'play.txt.normal': '普通',
  'play.txt.recharge': '充值',
  'play.txt.time': 'VIP将于{time}到期',
  'play.item.vip': '会员充值',
  'play.item.recharge': 'K币充值',
  'play.item.details': 'K币明细',
  'play.item.gold': '金币明细',
  'play.txt.details': '暂无K币明细',
  'play.txt.no_recharge': '暂无充值记录',
  'play.txt.no_gold': '暂无金币记录',
  'play.txt.purchase_time': '购买时间',
  'play.txt.order': '订单号',
  'play.txt.order_err': '订单处理失败，请重试',
  'play.txt.status_err': '支付失败',
  'play.txt.status_suc': '支付成功',
  'play.txt.balance': 'K币余额',
  'play.txt.balance1': '余额',
  'play.txt.episode.k': '本集',
  'play.txt.episode.v': '50 K币或奖金',
  'play.txt.more': '没有更多了',
  'play.txt.bonus': '充值加赠送',
  'play.txt.tips': 'K币余额不足，请充值',
  'play.txt.wallet': '我的钱包',
  'play.txt.desc': '免费畅享所有短剧',
  'play.txt.to-bind': '去绑定',
  'play.txt.unlockall': '解锁全集',
  'play.txt.limitpay': '限时特惠',

  // 充值
  'play.txt.pay': '立即支付',
  'play.txt.rurchase': '恢复购买',
  'play.txt.pack': 'VIP超值礼包',
  'play.get.coins': '送{coins}K币',
  'play.txt.notice': '须知',
  'play.txt.btn1': '已完成支付',
  'play.txt.btn2': '支付遇到问题，重新支付',
  'play.txt.btn3': '解锁',
  'play.txt.desc1': '1.账号所拥有的的虚拟货币及权益，应属于网络服务， 购买后不可退款；',
  'play.txt.desc2':
    '2.如您购买了自动订阅服务，为保障您的权益，需要打 开Google Play应用手动取消订阅；',
  'play.txt.desc3': '2.充值成功后，但余额未发生变化，请点击“恢复购买” 刷新；',
  'play.txt.desc4': '3.如有其他问题联系客服。',
  'play.txt.desc5': '请确认{type}付款是否已完成',
  'play.txt.desc6': '需要帮助? 请参考我们的帮助中心或联系我们',
  'play.txt.desc7': '为了保护您在TokTV上的数字资产，我们建议您使用Facebook或Google账户购买K币',

  //设置
  'setting.title': '设置',
  'setting.menu1': '切换语言',
  'setting.menu2': '用户协议',
  'setting.menu3': '隐私政策',
  'setting.menu4': '联系我们',
  'setting.menu4.desc1': '官方客服邮箱',
  'setting.menu4.desc2': '我们收到邮件后会很快回复您',

  //消息
  'message.desc1': '请求失败，点击重新加载',
  'message.desc2': '刷新成功',
  'message.desc3': '安装成功',

  //获得弹框免费解锁
  'free.unlock.text1': '恭喜获得',
  'free.unlock.text2': '免费解锁更多剧集',
  'free.unlock.text3': '立即下载解锁',

  //支付挽留弹框
  'pay.hold.text1': '内测用户专享福利',
  'pay.hold.text2': '限时',
  'pay.hold.text3': '折扣价',
  'pay.hold.text4': 'VIP免费看',
  'pay.hold.text5': '高清品质',
  'pay.hold.text6': '尊贵身份',
  'pay.hold.text7': '立即支付',
  'pay.hold.text8': '刚刚购买了',

  //福利界面
  'task.wallet': '我的钱包',
  'task.k': 'K币',
  'task.gold': '金币',
  'task.continuous': '连续签到：{ day }天',
  'task.day': '第{ day }天',
  'task.sign.btn': '签到',
  'task.day.text': '每日任务',
  'task.newuser.text': '新手任务',
  'task.lockvideo.text': '看剧奖励',
  'task.btn.todo': '去完成',
  'task.btn.Receive': '领取',
  'task.lock.episodes': '观看{ episodes }集剧',
  'task.signsuc.text': '签到成功',
  'task.continuous.suc.desc': '连续7天完成签到，可以获得双倍奖励',
  'task.again.get': '再领{ gold }金币',
  'task.get.title': '恭喜你获得',
  'task.scanad.double': '看广告双倍领取',
  'task.btn.ok': '好的',
  'task.btn.done': '已完成',
  'task.btn.comeback': '明天再来',
  'task.turntable.desc1': '100%幸运转盘',
  'task.turntable.desc2': '超多奖励等你来拿',
  'task.ad': '看广告获取金币',
  'task.ad.btn': '观看',

  //大转盘界面
  'turntable.title': '幸运转盘',
  'turntable.drawnum': '今日剩余{ num }次机会',
  'turntable.drawbtn': '免费抽奖',
  'turntable.rule': '活动规则',
  'turntable.rule1': '1.参与本活动，即表示同意活动规则，每天可参与{ total }次抽奖；',
  'turntable.rule2': '2.用户通过免费抽奖可获得金币，中途随机出现广告任务，完成后能获取抽奖机会；',
  'turntable.rule3': '3.本活动最终解释权归公司所有',

  //国家/地区
  'region.title': '国家/地区',
  'region.choose.wallet': '请选择钱包',
  'region.choose.country': '请选择',

  //视频页面支付挽留弹框
  'payhold.video.title': '专属礼物',
  'payhold.video.limit': '限时特惠',

  //首页支付挽留弹框
  'payhold.home.title': '超值特惠',
  'payhold.home.until': '降价到',
  'payhold.home.buy': '立即购买',
  'payhold.home.limit': '后结束',
  'payhole.home.bouns': '开通会员解锁更多专属权益',

  // 活动页面
  'act.title1': '网上赚钱',
  'act.desc1': '分享短剧你将获得充值金额的',
  'act.desc2': '',
  'act.title2': '立即注册',
  'act.tg.chat': 'TG聊天',
  'act.tg.channel': 'TG频道',
  'act.tg.bot': 'TG机器人',
};
