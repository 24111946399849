import { lang } from './../components/login/lang';

/**
 * lang: 繁體中文（港）
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': '請登錄',
  'common.toast.error.network': '網絡異常，請重試',
  'common.toast.error.params': '參數錯誤',
  'common.btn.drama.watching': '去追劇',
  'common.list.finished': '沒有更多了',
  'common.btn.back.home': '返迴',
  'common.text.text1': '向上滑動觀看下一集',

  // 剧集相关
  'drama.episode': '第{count}集',
  'drama.watch.episode': '看到第{count}集',
  'drama.total.episodes': '共{count}集',
  'drama.selections': '選集',
  'drama.lock.toast': '中間的故事也非常有趣，不要錯過！',

  // 登录
  // 'login.description': '歡迎使用 TokTV',
  // 'login.tip.term': '繼續操作即表示您同意本條款和隱私政策',
  // 'login.btn.google': 'Google登錄',
  // 'login.btn.facebook': 'Facebook登錄',
  // 'login.btn.logging': '登錄中...',
  // 'login.name.default': '歡迎登入',
  // 'login.google.label': '登入後解鎖更多劇集',
  ...lang.cnt,

  // 首页
  'home.nav.record': '觀看歷史',
  'home.nav.logout': '退出登錄',
  'home.nav.vip': 'VIP中心',
  'home.btn.download': '下載APP',
  'home.btn.open': '打開APP，看你感興趣的視頻',
  'home.tabs.tab1': '熱門',
  'home.tabs.tab2': '發現',
  'home.tabs.tab3': '推薦',
  'home.tabs.tab4': '首頁',
  'home.tabs.tab5': '追劇',
  'home.tabs.tab6': '我的',
  'home.tabs.tab7': '記錄',
  'home.tabs.tab8': '福利',

  // 观看历史
  'record.title': '觀看歷史',
  'record.title.share': '分享',
  'record.btn.share': '分享',
  'record.btn.playing': '繼續播放',
  'record.toast.copied': '複製成功',
  'record.empty': '暫無觀看記錄',

  // 分享页面
  'share.description': '打開Toktv觀看完整視頻。',
  'share.btn.open': '打開',
  'share.facebook.title':
    '在TokTv上觀看高清短劇，涵蓋現代、總裁、復仇、驚悚、穿越、喜劇和其他最新熱門類型的短劇。選擇適合您口味的類型。使用TokTv升級您的娛樂體驗。網址：https://m.toktv.cc',

  // 播放页面
  'video.play': '播放',
  'video.tips': '若播放卡頓，前往APP觀看',
  'video.select': '選集',
  'video.nomore': '已經到底了',
  'video.nolog': '暫無追劇記錄',
  'video.collect': '收藏',
  'video.havcollected': '已收藏',
  'video.title1': '新片上架',
  'video.title2': '現在熱播',
  'video.title3': '猜你喜歡',
  'video.guess.like': '90%的人都愛看',

  'video.wp.title': '劇集預告',
  'video.wp.desc1': '強烈推薦接收來自Toktv的精彩劇集通知！',
  'video.wp.desc2': '如果您取消，您將不會收到新劇、精彩劇集和特殊福利的每日通知。',
  'video.wp.btn.txt': '允許',

  // 下载页面
  'download.btn': '立即下載',
  'download.btn1': '一鍵安裝，解鎖精彩短劇',
  'download.btn2': '正在安裝',
  'download.btn3': '立即打開',

  // 会员中心
  'play.txt.kb': 'K幣',
  'play.txt.account': '帳戶',
  'play.txt.normal': '普通',
  'play.txt.recharge': '充值',
  'play.txt.time': 'VIP將於{time}到期',
  'play.item.vip': '會員充值',
  'play.item.recharge': 'K幣充值',
  'play.item.details': 'K幣明細',
  'play.item.gold': '金幣明細',
  'play.txt.details': '暫無K幣明細',
  'play.txt.no_recharge': '暫無充值記錄',
  'play.txt.no_gold': '暫無金幣記錄',
  'play.txt.purchase_time': '購買時間',
  'play.txt.order': '訂單號',
  'play.txt.order_err': '訂單處理失敗，請重試',
  'play.txt.status_err': '支付成功',
  'play.txt.status_suc': '支付失敗',
  'play.txt.balance': 'K幣餘額',
  'play.txt.balance1': '餘額',
  'play.txt.episode.k': '本集',
  'play.txt.episode.v': '50 K幣或獎金',
  'play.txt.more': '沒有更多了',
  'play.txt.bonus': '儲值加贈',
  'play.txt.tips': 'K幣不足，請充值',
  'play.txt.wallet': '我的錢包',
  'play.txt.desc': '免費暢享所有短劇',
  'play.txt.to-bind': '去綁定',
  'play.txt.unlockall': '解鎖全集',
  'play.txt.limitpay': '限時特惠',

  // 充值
  'play.txt.pay': '立即支付',
  'play.txt.rurchase': '恢復購買',
  'play.txt.pack': 'VIP超值禮包',
  'play.get.coins': '贈送{coins}K幣',
  'play.txt.notice': '須知',
  'play.txt.btn1': '已完成支付',
  'play.txt.btn2': '支付遇到問題，重新支付',
  'play.txt.btn3': '解鎖',
  'play.txt.desc1': '賬號所擁有的虛擬貨幣及權益，應屬於網絡服務，購買後不可退款；',
  'play.txt.desc2': '如您購買了自動訂閱服務，為保障您的權益，需要打開Google Play應用手動取消訂閱；',
  'play.txt.desc3': '充值成功後，但餘額未發生變化，請點擊“恢復購買” 刷新；',
  'play.txt.desc4': '如有其他問題請聯繫客服。',
  'play.txt.desc5': '請確認{type}付款是否已完成',
  'play.txt.desc6': '需要協助? 請參考我們的幫助中心或聯絡我們',
  'play.txt.desc7': '為了保護您在TokTV上的數位資產，我們建議您使用Facebook或Google帳號購買K幣',

  //设置
  'setting.title': '設定',
  'setting.menu1': '切換語言',
  'setting.menu2': '使用者協定',
  'setting.menu3': '隱私政策',
  'setting.menu4': '聯繫我們',
  'setting.menu4.desc1': '官方客服郵箱',
  'setting.menu4.desc2': '我們收到郵件會很快回復您',

  //消息
  'message.desc1': '请求失败，点击重新加载',
  'message.desc2': '刷新成功',
  'message.desc3': '安裝成功',

  //获得弹框免费解锁
  'free.unlock.text1': '恭喜獲得',
  'free.unlock.text2': '免費解鎖更多劇集',
  'free.unlock.text3': '立即下載解鎖',

  //支付挽留弹框
  'pay.hold.text1': '內測用戶專享福利',
  'pay.hold.text2': '限時',
  'pay.hold.text3': '折扣價',
  'pay.hold.text4': 'VIP免費看',
  'pay.hold.text5': '高清品質',
  'pay.hold.text6': '尊貴身份',
  'pay.hold.text7': '立即支付',
  'pay.hold.text8': '剛剛購買了',

  //福利界面
  'task.wallet': '我的錢包',
  'task.k': 'K幣',
  'task.gold': '金幣',
  'task.continuous': '連續簽到：{ day }天',
  'task.day': '第{ day }天',
  'task.sign.btn': '簽到',
  'task.day.text': '每日任務',
  'task.newuser.text': '新手任務',
  'task.lockvideo.text': '看劇獎勵',
  'task.btn.todo': '去完成',
  'task.btn.Receive': '領取',
  'task.lock.episodes': '觀看{ episodes }集劇',
  'task.signsuc.text': '簽到成功',
  'task.continuous.suc.desc': '連續7天完成簽到，可以獲得雙倍獎勵',
  'task.again.get': '再領{ gold }金幣',
  'task.get.title': '恭喜你獲得',
  'task.scanad.double': '看廣告雙倍領取',
  'task.btn.ok': '好的',
  'task.btn.done': '已完成',
  'task.btn.comeback': '明天再來',
  'task.turntable.desc1': '100%幸運轉盤',
  'task.turntable.desc2': '超多金幣等你來拿',
  'task.ad': '看廣告獲取金幣',
  'task.ad.btn': '觀看',

  //大转盘界面
  'turntable.title': '幸運轉盤',
  'turntable.drawnum': '今日剩餘{ num }次機會',
  'turntable.drawbtn': '免費抽獎',
  'turntable.rule': '活動規則',
  'turntable.rule1': '1.參與本活動，即表示同意活動規則，每天可參與{ total }次抽獎；',
  'turntable.rule2': '2.用戶通過免費抽獎可獲得金幣，中途隨機出現廣告任務，完成後能獲取抽獎機會；',
  'turntable.rule3': '3.本活動最終解釋權歸公司所有',

  //国家/地区
  'region.title': '國家/地區',
  'region.choose.wallet': '請選擇錢包',
  'region.choose.country': '請選擇',

  //视频页面支付挽留弹框
  'payhold.video.title': '專屬禮物',
  'payhold.video.limit': '限時特惠',

  //首页支付挽留弹框
  'payhold.home.title': '超值特惠',
  'payhold.home.until': '降價到',
  'payhold.home.buy': '立即購買',
  'payhold.home.limit': '後結束',
  'payhole.home.bouns': '開通會員解鎖更多專屬權益',

  // 活动页面
  'act.title1': '網上賺錢',
  'act.desc1': '分享短劇你將獲得充值金額的',
  'act.desc2': '',
  'act.title2': '立即註冊',
  'act.tg.chat': 'TG聊天',
  'act.tg.channel': 'TG頻道',
  'act.tg.bot': 'TG機器人',
};
