/**
 * lang: 越南语
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': 'Vui lòng đăng nhập',
  'common.toast.error.network': 'Lỗi mạng, vui lòng thử lại',
  'common.toast.error.params': 'Tham số sai',
  'common.btn.drama.watching': 'Bắt đầu xem',
  'common.list.finished': 'Không còn nữa',
  'common.btn.back.home': 'Trở về',
  'common.text.text1': 'Trượt lên và xuống Xem thêm',

  // 剧集相关
  'drama.episode': 'Tập {count}',
  'drama.watch.episode': 'Xem đến tập {count}',
  'drama.total.episodes': 'Tổng cộng {count} tập',
  'drama.selections': 'Chọn tập',

  // 登录
  'login.description': 'Đăng nhập để có trải nghiệm tốt hơn',
  'login.tip.term':
    'Tiếp tục đồng nghĩa với việc bạn đồng ý với các điều khoản và chính sách bảo mật',
  'login.btn.google': 'Đăng nhập bằng Google',
  'login.btn.logging': 'Đang đăng nhập ...',

  // 首页
  'home.nav.record': 'Lịch sử xem',
  'home.nav.logout': 'Đăng xuất',
  'home.nav.vip': 'Trung tâm VIP',
  'home.btn.download': 'Tải ứng dụng',
  'home.btn.open': 'Mở ứng dụng và xem video bạn quan tâm.',
  'home.tabs.tab1': 'Nóng nhất',
  'home.tabs.tab2': 'Trang chủ',
  'home.tabs.tab3': 'Được đề nghị',
  'home.tabs.tab4': 'Trang chủ',
  'home.tabs.tab5': 'Xem yêu quái',
  'home.tabs.tab6': 'Trung tâm người dùng',
  'home.tabs.tab7': 'Thu âm.',
  'home.tabs.tab8': 'Phúc lợi',

  // 观看历史
  'record.title': 'Lịch sử xem',
  'record.title.share': 'Chia sẻ',
  'record.btn.share': 'Chia sẻ',
  'record.btn.playing': 'Chơi',
  'record.toast.copied': 'Sao chép thành công',
  'record.empty': 'Không tìm thấy dữ liệu',

  // 分享页面
  'share.description': 'Mở Toktv để xem video đầy đủ',
  'share.btn.open': 'Mở',
  'share.facebook.title': '',

  // 播放页面
  'video.play': 'Chơi',
  'video.tips': 'Nếu bị kẹt, hãy truy cập APP',
  'video.select': 'Hồi ỨC',
  'video.nomore': 'Nó đã kết thúc rồi',
  'video.nolog': 'Anh vẫn chưa xem video nào.',
  'video.collect': 'Danh sách của tôi.',
  'video.havcollected': 'Yêu thích',
  'video.title1': 'Ấn bản mới',
  'video.title2': 'Được ưa chuộng nhất',
  'video.title3': 'được đề nghị',
  'video.guess.like': '',

  // 下载页面
  'download.btn': 'Tải ứng dụng',

  // 会员中心
  'play.txt.kb': 'Điểm K',
  'play.txt.account': 'Tài khoản',
  'play.txt.normal': 'Bình thường',
  'play.txt.recharge': 'Nạp tiền',
  'play.txt.time': 'Tài khoản VIP sẽ hết hạn vào ngày {time}',
  'play.item.vip': 'Nạp tiền thành viên',
  'play.item.recharge': 'nạp xu K',
  'play.item.details': 'Thông tin chi tiết về đồng K',
  'play.item.gold': 'Chi tiết tiền xu',
  'play.txt.details': 'Chưa có chi tiết Điểm K',
  'play.txt.no_recharge': 'Chưa có lịch sử nạp tiền',
  'play.txt.no_gold': 'Không có tiền vàng',
  'play.txt.purchase_time': 'Thời gian mua hàng',
  'play.txt.order': 'Số đơn hàng',
  'play.txt.order_err': 'Xử lý đơn hàng không thành công, vui lòng thử lại',
  'play.txt.status_err': 'Thanh toán không thành công',
  'play.txt.status_suc': 'thanh toán thành công',
  'play.txt.balance': 'Số dư tiền K',
  'play.txt.balance1': 'Số dư',
  'play.txt.more': 'Không còn nữa',
  'play.txt.bonus': 'Phần thưởng giá trị được lưu trữ',
  'play.txt.tips': 'Không đủ xu K, vui lòng nạp lại',
  'play.txt.wallet': 'Ví tiền',
  'play.txt.desc': 'Thưởng thức tất cả các món đồ chơi miễn phí',
  'play.txt.unlockall': '',
  'play.txt.limitpay': '',

  // 充值
  'play.txt.pay': 'Thanh toán ngay',
  'play.txt.rurchase': 'Khôi phục mua hàng',
  'play.txt.pack': 'Gói quà siêu giá trị VIP',
  'play.get.coins': 'Tặng {coins} Điểm K',
  'play.txt.notice': 'Lưu ý',
  'play.txt.btn1': '',
  'play.txt.btn2': '',
  'play.txt.btn3': '',
  'play.txt.desc1':
    'Tiền ảo và quyền lợi thuộc sở hữu của tài khoản nên thuộc dịch vụ mạng, không thể hoàn tiền sau khi mua;',
  'play.txt.desc2':
    'Nếu bạn mua dịch vụ đăng ký tự động, để bảo vệ quyền lợi của bạn, bạn cần mở ứng dụng Google Play và hủy đăng ký thủ công;',
  'play.txt.desc3':
    'Sau khi nạp tiền thành công nhưng số dư không thay đổi, vui lòng nhấp vào "Khôi phục mua hàng" để làm mới;',
  'play.txt.desc4': 'Nếu có bất kỳ vấn đề nào khác, vui lòng liên hệ dịch vụ khách hàng.',
  'play.txt.desc5': '',
  'play.txt.desc6': '',
  'play.txt.desc7': '',

  //设置
  'setting.title': 'Thiết lập',
  'setting.menu1': 'Chuyển đổi ngôn ngữ',
  'setting.menu2': 'Thỏa thuận người dùng',
  'setting.menu3': 'Chính sách bảo mật',
  'setting.menu4': 'Liên hệ',
  'setting.menu4.desc1': 'Hộp thư chính thức',
  'setting.menu4.desc2': 'Chúng tôi sẽ trả lời bạn sớm khi nhận được tin nhắn',

  //消息
  'message.desc1': 'Yêu cầu không thành công, vui lòng thử lại',
  'message.desc2': 'Nhẹ nhàng.',
  'message.desc3': 'Cài đặt thành công',

  //支付挽留弹框
  'pay.hold.text1': 'Phúc lợi xã hội',
  'pay.hold.text2': 'Giới hạn thời gian',
  'pay.hold.text3': 'Giảm giá',
  'pay.hold.text4': 'xem miễn phí',
  'pay.hold.text5': 'Chất lượng HD',
  'pay.hold.text6': 'Danh tính cao quý',
  'pay.hold.text7': 'Thanh toán ngay',
  'pay.hold.text8': 'đã mua',

  //福利界面
  'task.wallet': 'Ví của tôi',
  'task.k': 'Đồng xu K',
  'task.gold': 'Vàng',
  'task.continuous': 'Đăng ký liên tục: { day } ngày',
  'task.day': 'Ngày { day }',
  'task.sign.btn': 'Đăng ký',
  'task.day.text': 'Nhiệm vụ hàng ngày',
  'task.newuser.text': 'Nhiệm vụ Newbie',
  'task.lockvideo.text': 'Xem phim thưởng',
  'task.btn.todo': 'Hoàn thành',
  'task.btn.Receive': 'Nhận',
  'task.lock.episodes': 'Xem { episodes } vở kịch',
  'task.signsuc.text': 'Đăng ký thành công',
  'task.continuous.suc.desc':
    'Hoàn thành đăng ký trong 7 ngày liên tiếp và nhận được phần thưởng gấp đôi',
  'task.again.get': 'Nhận tiền xu { gold }',
  'task.get.title': 'Chúc mừng bạn đã nhận được',
  'task.scanad.double': 'Xem quảng cáo nhận gấp đôi',
  'task.btn.ok': 'Được rồi.',
  'task.btn.done': 'Đã hoàn thành',
  'task.btn.comeback': 'Trở lại vào ngày mai',
  'task.turntable.desc1': 'Bàn xoay may mắn 100%',
  'task.turntable.desc2': 'Rất nhiều vàng đang chờ bạn.',
  'task.ad': 'Xem quảng cáo tiền vàng',
  'task.ad.btn': 'Xem',

  //大转盘界面
  'turntable.title': 'Bàn xoay may mắn',
  'turntable.drawnum': 'Hôm nay { num } cơ hội',
  'turntable.drawbtn': 'Rút thăm miễn phí',
  'turntable.rule': 'Quy tắc hoạt động',
  'turntable.rule1':
    '1. Tham gia vào sự kiện này, bằng cách đồng ý với các quy tắc của sự kiện, có thể tham gia { total } lần rút thăm trúng thưởng mỗi ngày;',
  'turntable.rule2':
    '2. Người sử dụng có thể nhận được tiền vàng thông qua rút thăm trúng thưởng miễn phí, giữa chừng ngẫu nhiên xuất hiện nhiệm vụ quảng cáo, sau khi hoàn thành có thể nhận được cơ hội rút thăm trúng thưởng;',
  'turntable.rule3': '3. Quyền giải thích cuối cùng về hoạt động này thuộc về Công ty',

  //国家/地区
  'region.title': 'Quốc gia',
  'region.choose.wallet': 'Vui lòng chọn ví',
  'region.choose.country': 'Vui lòng chọn',
};
