import { i18n } from '@/locale';
import { showToast } from 'vant';
import clipboard from 'vue-clipboard3';

/**
 * 复制
 */
export const useCopy = async (text: string) => {
  const { toClipboard } = clipboard();
  const { t } = i18n.global;
  try {
    console.log('Copied to clipboard');
    const res = await toClipboard(text);
    showToast(t('record.toast.copied'));
    return res;
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};
