import { lang } from './../components/login/lang';

/**
 * lang: 英语
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': 'Please login',
  'common.toast.error.network': 'Network exception',
  'common.toast.error.params': 'Parameter error',
  'common.btn.drama.watching': 'Chasing Drama',
  'common.list.finished': 'No more',
  'common.btn.back.home': 'back',
  'common.text.text1': 'Swipe up for next episode',

  // 剧集相关
  'drama.episode': 'Episode {count}',
  'drama.watch.episode': 'Currently on episode {count}',
  'drama.total.episodes': 'Total {count} episodes',
  'drama.selections': 'Episodes',
  'drama.lock.toast': "The story in the middle is also very interesting, don't miss it!",

  // 登录
  // 'login.description': 'Welcome to TokTV',
  // 'login.tip.term': 'Continuing indicates agreement with our terms and privacy policy',
  // 'login.btn.google': 'Login with Google',
  // 'login.btn.facebook': 'Login with Facebook',
  // 'login.btn.logging': 'Logging ...',
  // 'login.name.default': 'Login please',
  // 'login.google.label': 'Login to unlock more episodes',
  ...lang.en,

  // 首页
  'home.nav.record': 'Viewing History',
  'home.nav.logout': 'Logout',
  'home.nav.vip': 'VIP Center',
  'home.btn.download': 'Download the app',
  'home.btn.open': "Open the app and watch the videos you're interested in",
  'home.tabs.tab1': 'Hot',
  'home.tabs.tab2': 'Discover',
  'home.tabs.tab3': 'For You',
  'home.tabs.tab4': 'Home',
  'home.tabs.tab5': 'My List',
  'home.tabs.tab6': 'Profile',
  'home.tabs.tab7': 'Play List',
  'home.tabs.tab8': 'Welfare',

  // 观看历史
  'record.title': 'Viewing History',
  'record.title.share': 'Share',
  'record.btn.share': 'Share',
  'record.btn.playing': 'Play',
  'record.toast.copied': 'Copy successful',
  'record.empty': 'No data',

  // 分享页面
  'share.description': 'Open Toktv to watch the full video',
  'share.btn.open': 'Open',
  'share.facebook.title':
    'Watch high-definition short dramas on TokTv, covering modern, CEO, revenge, thriller, time-travel, comedy, and other latest popular genres of short dramas. Choose the types that suit your taste. Upgrade your entertainment experience with TokTv. Website: https://m.toktv.cc',

  // 播放页面
  'video.play': 'Play',
  'video.tips': 'If Stuck, go to the APP',
  'video.select': 'List',
  'video.nomore': "It's already the end of it",
  'video.nolog': "You haven't watched any videos yet.",
  'video.collect': 'Collect',
  'video.havcollected': 'Collected',
  'video.title1': 'New Release',
  'video.title2': 'Now Trending',
  'video.title3': 'Recommended for You',
  'video.guess.like': 'Loved by 90% of people',

  'video.wp.title': 'Drama Notice',
  'video.wp.desc1': 'Highly recommended to receive exciting episode notifications from Toktv!!!',
  'video.wp.desc2':
    'If you cancel, you will not receive daily notifications of new dramas, exciting episodes, and special benefits.',
  'video.wp.btn.txt': 'Allow',

  // 下载页面
  'download.btn': 'Download the app',
  'download.btn1': 'Install Unlock skits',
  'download.btn2': 'Installing',
  'download.btn3': 'Open now',

  // 会员中心
  'play.txt.kb': 'K Coins',
  'play.txt.account': 'Account',
  'play.txt.normal': 'Normal',
  'play.txt.recharge': 'Recharge',
  'play.txt.time': 'VIP will expire on {time}',
  'play.item.vip': 'Member recharge',
  'play.item.recharge': 'K coin recharge',
  'play.item.gold': 'Gold coin detail',
  'play.item.details': 'K coin details',
  'play.txt.details': 'No K Coin Details Available',
  'play.txt.no_recharge': 'No Recharge Records Available',
  'play.txt.no_gold': 'No gold coins Records Available',
  'play.txt.purchase_time': 'Purchase Time',
  'play.txt.order': 'Order Number',
  'play.txt.order_err': 'Order processing failed, please try again',
  'play.txt.status_err': 'Payment failed',
  'play.txt.status_suc': 'Payment successful',
  'play.txt.balance': 'K coin balance',
  'play.txt.balance1': 'Balance',
  'play.txt.episode.k': 'This Episode',
  'play.txt.episode.v': '50 K Coins Or Bonus',
  'play.txt.more': 'No more',
  'play.txt.bonus': 'Recharge and get bonus',
  'play.txt.tips': 'Insufficient K coins, please recharge',
  'play.txt.wallet': 'Wallet',
  'play.txt.desc': 'Enjoy all playlets for free',
  'play.txt.to-bind': 'Bind',
  'play.txt.unlockall': 'Unlock all',
  'play.txt.limitpay': 'Limited offer',

  // 充值
  'play.txt.pay': 'Pay Now',
  'play.txt.rurchase': 'Restore Purchase',
  'play.txt.pack': 'VIP Value Pack',
  'play.get.coins': 'Get {coins} K Coins',
  'play.txt.notice': 'Notice',
  'play.txt.btn1': 'Payment completed',
  'play.txt.btn2': 'There is a problem with payment, please pay again',
  'play.txt.btn3': 'Unlock',
  'play.txt.desc1':
    'The virtual currency and rights owned by the account should belong to the network service and are non-refundable after purchase.',
  'play.txt.desc2':
    'If you have purchased an auto-subscription service, to protect your rights, you need to manually cancel the subscription in the Google Play application.',
  'play.txt.desc3':
    'If the recharge is successful but the balance has not changed, please click "Restore Purchase" to refresh.',
  'play.txt.desc4': 'If you have any other questions, please contact customer service.',
  'play.txt.desc5': 'please confirm {type} Has the payment been completed?',
  'play.txt.desc6': 'Need help? Please refer to our Help Center or contact us',
  'play.txt.desc7':
    'In order to protect your digital assets on TokTV, we recommend that you use your Facebook or Google account to purchase K coins',

  //设置
  'setting.title': 'Settings',
  'setting.menu1': 'switch language',
  'setting.menu2': 'User Agreement',
  'setting.menu3': 'Privacy Policy',
  'setting.menu4': 'contact us',
  'setting.menu4.desc1': 'Official customer service email',
  'setting.menu4.desc2': 'We will reply to your email as soon as we receive it',

  //消息
  'message.desc1': 'Request failed, try again',
  'message.desc2': 'Refreshed',
  'message.desc3': 'Installed successfully',

  //获得弹框免费解锁
  'free.unlock.text1': 'Download now to unlock',
  'free.unlock.text2': 'Unlock more episodes for free',
  'free.unlock.text3': 'Unlock now',

  //支付挽留弹框
  'pay.hold.text1': 'Exclusive benefit',
  'pay.hold.text2': 'Limited time',
  'pay.hold.text3': 'Discount',
  'pay.hold.text4': 'Free Viewing',
  'pay.hold.text5': 'High Definition',
  'pay.hold.text6': 'Noble status',
  'pay.hold.text7': 'Pay Now',
  'pay.hold.text8': 'just made a purchase',

  //福利界面
  'task.wallet': 'Wallet',
  'task.k': 'K Coins',
  'task.gold': 'Gold Coins',
  'task.continuous': 'Consecutive check-ins: { day } days',
  'task.day': 'Day { day }',
  'task.sign.btn': 'Check-in',
  'task.day.text': 'Daily task',
  'task.newuser.text': 'Beginners tasks',
  'task.lockvideo.text': 'Watch show rewards',
  'task.btn.todo': 'todo',
  'task.btn.Receive': 'Receive',
  'task.lock.episodes': 'Watch { episodes } episodes',
  'task.signsuc.text': 'Check-in successful',
  'task.continuous.suc.desc': 'Completing 7 consecutive days of check-ins earns double rewards',
  'task.again.get': 'Receiving { gold } gold coins',
  'task.get.title': 'Congratulations on receiving',
  'task.scanad.double': 'Watch advertisements for double rewards',
  'task.btn.ok': 'Okay',
  'task.btn.done': 'Received',
  'task.btn.comeback': 'Come back tomorrow',
  'task.turntable.desc1': '100% Lucky Wheel',
  'task.turntable.desc2': 'A lot of gold coins waiting for you to pick up',
  'task.ad': 'Receive coins by watching advertisements',
  'task.ad.btn': 'watch',

  //大转盘界面
  'turntable.title': 'Lucky Wheel',
  'turntable.drawnum': '{ num } remaining opportunities today',
  'turntable.drawbtn': 'Free lottery',
  'turntable.rule': 'Activity rules',
  'turntable.rule1':
    '1. By participating in this activity, one agrees to the activity rules and can participate in { total } lucky draws per day;',
  'turntable.rule2':
    '2. Users can receive gold coins through free lottery, and ad tasks randomly appear midway. After completion, they can receive a lottery opportunity;',
  'turntable.rule3': '3. The final interpretation right of this activity belongs to the company',

  //国家/地区
  'region.title': 'Country/Region',
  'region.choose.wallet': 'Please select a wallet',
  'region.choose.country': 'Please select',

  //视频页面支付挽留弹框
  'payhold.video.title': 'Exclusive gift',
  'payhold.video.limit': 'Limited offer',

  //首页支付挽留弹框
  'payhold.home.title': ' Special offer',
  'payhold.home.until': 'Reduced to',
  'payhold.home.buy': 'Buy Now',
  'payhold.home.limit': 'Till it ends',
  'payhole.home.bouns': 'Unlock more benefits',

  // 活动页面
  'act.title1': 'Make Money Online',
  'act.desc1': 'Share the short drama and you will get',
  'act.desc2': 'of the top up amount',
  'act.title2': 'sign up now',
  'act.tg.chat': 'TG chat',
  'act.tg.channel': 'TG channel',
  'act.tg.bot': 'TG bot',
};
