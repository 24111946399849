import type { App } from 'vue';
import { I18nOptions, createI18n } from 'vue-i18n';
import { LocaleLangEnum } from './lang';
import en from './en-US';
import cn from './zh-CN';
import hk from './zh-HK';
import vn from './vi-VN';
import th from './th-TH';
import id from './id-ID';
import pt from './pt-BR';
import es from './es-ES';
import { getCurrentLang } from '@/utils';

// const { t } = useI18n();
// t('xx')
// or
// $t('xxx')

const defaultLocale = getCurrentLang();

const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: LocaleLangEnum.enUS,
  legacy: false,
  allowComposition: true,
  messages: {
    [LocaleLangEnum.enUS]: en,
    [LocaleLangEnum.zhCN]: cn,
    [LocaleLangEnum.zhHK]: hk,
    [LocaleLangEnum.viVN]: vn,
    [LocaleLangEnum.thTH]: th,
    [LocaleLangEnum.idID]: id,
    [LocaleLangEnum.ptBR]: pt,
    [LocaleLangEnum.esEs]: es,
  } as I18nOptions['messages'],
});

export { i18n };

export function setupLocale(app: App<Element>) {
  app.use(i18n);
}
