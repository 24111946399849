import { lang } from './../components/login/lang';

/**
 * lang: 葡萄牙（巴西）
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': 'Por favor, faça login',
  'common.toast.error.network': 'Erro de rede, por favor, tente novamente',
  'common.toast.error.params': 'Erro de parâmetro',
  'common.btn.drama.watching': 'Assistir a série',
  'common.list.finished': 'Sem mais',
  'common.btn.back.home': 'regresso',
  'common.text.text1': 'Deslize p/ ver próximo episódio',

  // 剧集相关
  'drama.episode': 'EP.{count}',
  'drama.watch.episode': 'Assistir ao Ep.{count}',
  'drama.total.episodes': '{count} episódio no total',
  'drama.selections': 'Selecionar episódio',
  'drama.lock.toast': 'A história no meio também é muito interessante, não perca!',

  // 登录
  // 'login.description': 'Bem-vindo ao TokTV',
  // 'login.tip.term': 'Continuar indica que você concorda com os termos e política de privacidade',
  // 'login.btn.google': 'Entrar com o Google',
  // 'login.btn.facebook': 'Entrar com o Facebook',
  // 'login.btn.logging': 'Iniciando sessão...',
  // 'login.name.default': 'Faça login por favor',
  // 'login.google.label': 'Faça login para desbloquear eps',
  ...lang.pt,

  // 首页
  'home.nav.record': 'Histórico de visualizações',
  'home.nav.logout': 'Sair',
  'home.nav.vip': 'Centro VIP',
  'home.btn.download': 'Baixar o aplicativo',
  'home.btn.open': 'Abra o aplicativo e assista aos vídeos que você está interessado',
  'home.tabs.tab1': 'Populares',
  'home.tabs.tab2': 'Descobrir',
  'home.tabs.tab3': 'Recomendar',
  'home.tabs.tab4': 'Início',
  'home.tabs.tab5': 'My List',
  'home.tabs.tab6': 'Perfil',
  'home.tabs.tab7': 'História',
  'home.tabs.tab8': 'Bem-estar',

  // 观看历史
  'record.title': 'Histórico de visualizações',
  'record.title.share': 'Compartilhar',
  'record.btn.share': 'Compartilhar',
  'record.btn.playing': 'Continuar assistindo',
  'record.toast.copied': 'Copiado com sucesso',
  'record.empty': 'Nenhuma visualização recente',

  // 分享页面
  'share.description': 'Abrir o Toktv para assistir ao vídeo completo',
  'share.btn.open': 'Abrir',
  'share.facebook.title':
    'Assista a curtas em alta definição na TokTv, abrangendo géneros modernos, de CEO, vingança, thriller, viagens no tempo, comédia e outros géneros populares mais recentes. Escolha os tipos que mais lhe agradam. Atualize sua experiência de entretenimento com a TokTv. Website: https://m.toktv.cc',

  // 播放页面
  'video.play': 'jogar',
  'video.tips': 'Se houver atrasos na reprodução, vá para o aplicativo para assistir',
  'video.select': 'Lista',
  'video.nomore': 'Já é o fim disso',
  'video.nolog': 'Você ainda não viu vídeos.',
  'video.collect': 'Coletar',
  'video.havcollected': 'Coletado',
  'video.title1': 'Novos lançamentos',
  'video.title2': 'Atualmente em alta',
  'video.title3': 'Sugerido para você',
  'video.guess.like': 'Amado por 90% das pessoas',

  'video.wp.title': 'Aviso Dramático',
  'video.wp.desc1':
    'Altamente recomendado para receber notificações de episódios emocionantes da Toktv!!!',
  'video.wp.desc2':
    'Se cancelar, você não receberá notificações diárias de novos dramas, episódios emocionantes e benefícios especiais.',
  'video.wp.btn.txt': 'Permitir',

  // 下载页面
  'download.btn': 'Baixar Agora',
  'download.btn1': 'Instalar Desbloquear esquetes',
  'download.btn2': 'Instalando',
  'download.btn3': 'Abra agora',

  // 会员中心
  'play.txt.kb': 'Moeda K',
  'play.txt.account': 'Conta',
  'play.txt.normal': 'Normal',
  'play.txt.recharge': 'Recarga',
  'play.txt.time': 'A assinatura VIP expira em {time}',
  'play.item.vip': 'Recarga de assinatura',
  'play.item.recharge': 'Recarga de moedas K',
  'play.item.details': 'Detalhes das moedas K',
  'play.item.gold': 'Detalhes das moedas',
  'play.txt.details': 'Sem detalhes de K-coins no momento',
  'play.txt.no_recharge': 'Sem registros de recarga no momento',
  'play.txt.no_gold': 'Ainda não há registros de ouro',
  'play.txt.purchase_time': 'Data da Compra',
  'play.txt.order': 'Número do Pedido',
  'play.txt.order_err': 'Falha no processamento do pedido, tente novamente',
  'play.txt.status_err': 'Pagamento sem sucesso',
  'play.txt.status_suc': 'Pagamento bem-sucedido',
  'play.txt.balance': 'Saldo de K-coins',
  'play.txt.balance1': 'Saldo de',
  'play.txt.episode.k': 'Este episódio',
  'play.txt.episode.v': '50 moedas k ou bônus',
  'play.txt.more': 'Não há mais conteúdo',
  'play.txt.bonus': 'Recarga com bônus',
  'play.txt.tips': 'Saldo de K-coins insuficiente. Por favor, recarregue',
  'play.txt.wallet': 'carteira',
  'play.txt.desc': 'Aproveite todos os playlets de graça',
  'play.txt.to-bind': 'Vincular',
  'play.txt.unlockall': 'Desbloq. tudo',
  'play.txt.limitpay': 'Oferta limitada',

  // 充值
  'play.txt.pay': 'Pagar Agora',
  'play.txt.rurchase': 'Restaurar compra',
  'play.txt.pack': 'Pacote VIP com grande valor',
  'play.get.coins': 'Receba {coins} moedas K',
  'play.txt.notice': 'Nota',
  'play.txt.btn1': 'Pagamento completo',
  'play.txt.btn2': 'Há um problema com o pagamento, pague novamente',
  'play.txt.btn3': 'Desbloquear',
  'play.txt.desc1':
    '1.As moedas virtuais e direitos associados à conta pertencem aos serviços de rede, e uma vez adquiridos, não são reembolsáveis.',
  'play.txt.desc2':
    '2.Se você comprou um serviço de assinatura automática, para proteger seus direitos, é necessário abrir o aplicativo Google Play e cancelar a assinatura manualmente.',
  'play.txt.desc3':
    '3.Após o sucesso da recarga, mas sem alteração no saldo, por favor clique em "Restaurar compra" para atualizar.',
  'play.txt.desc4': '4.Se tiver outras dúvidas, entre em contato com o suporte ao cliente.',
  'play.txt.desc5': 'por favor confirme {type} O pagamento foi concluído?',
  'play.txt.desc6':
    'Precisa de ajuda? Consulte nossa Central de Ajuda ou entre em contato conosco.',
  'play.txt.desc7':
    'Para proteger seus ativos digitais na TokTV, recomendamos que você use sua conta do Facebook ou Google para comprar moedas K',

  //设置
  'setting.title': 'estabeleceu',
  'setting.menu1': 'trocar linguagem',
  'setting.menu2': 'Acordo de Utilizador',
  'setting.menu3': 'Política de Privacidade',
  'setting.menu4': 'contatem-nos',
  'setting.menu4.desc1': 'E-mail oficial do serviço de clientes',
  'setting.menu4.desc2': 'Nós responderemos ao seu e-mail assim que o recebemos',

  //消息
  'message.desc1': 'O pedido falhou, clique para recargar',
  'message.desc2': 'Refrescado.',
  'message.desc3': 'Instalação bem-sucedida',

  //获得弹框免费解锁
  'free.unlock.text1': 'Baixe agora para desbloquear',
  'free.unlock.text2': 'Desbloqueie mais episódios gratuitamente',
  'free.unlock.text3': 'Desbloquear agora',

  //支付挽留弹框
  'pay.hold.text1': 'Benefícios exclusivos para',
  'pay.hold.text2': 'Tempo limitado',
  'pay.hold.text3': 'desconto',
  'pay.hold.text4': 'Vista gratuita',
  'pay.hold.text5': 'Alta definição',
  'pay.hold.text6': 'Estado nobre',
  'pay.hold.text7': 'Pagar Agora',
  'pay.hold.text8': 'acabou de fazer uma compra',

  //福利界面
  'task.wallet': 'Minha carteira',
  'task.k': 'moedas de K',
  'task.gold': 'Monetas de ouro',
  'task.continuous': 'Verificação contínua: { day } dias',
  'task.day': '{ day }º dia',
  'task.sign.btn': 'Signar',
  'task.day.text': 'Missões do dia',
  'task.newuser.text': 'Nova tarefa',
  'task.lockvideo.text': 'Recompensas por assistir dramas',
  'task.btn.todo': 'Vai completar',
  'task.btn.Receive': 'receber',
  'task.lock.episodes': 'Vejam { episodes } episódios do drama',
  'task.signsuc.text': 'Verificação bem sucedida',
  'task.continuous.suc.desc':
    'Completar o check-in por 7 dias consecutivos pode ganhar o dobro da recompensa',
  'task.again.get': 'Recebendo moedas { gold }',
  'task.get.title': 'Parabéns por conseguir',
  'task.scanad.double': 'Ver anúncio double pick',
  'task.btn.ok': 'bom',
  'task.btn.done': 'Completado',
  'task.btn.comeback': 'Volte amanhã',
  'task.turntable.desc1': '100% Lucky Wheel',
  'task.turntable.desc2': 'Muitas moedas de ouro esperando que você pegue',
  'task.ad': 'Receber moedas observando anúncios',
  'task.ad.btn': 'assistir',

  //大转盘界面
  'turntable.title': 'Lucky Wheel',
  'turntable.drawnum': '{ num } as oportunidades restantes hoje',
  'turntable.drawbtn': 'Loteria gratuita',
  'turntable.rule': 'Regras de atividade',
  'turntable.rule1':
    '1. Ao participar dessa atividade, um concorda com as regras de atividade e pode participar em { total } desenhos de sorte por dia;',
  'turntable.rule2':
    '2. Os usuários podem receber moedas de ouro através da loteria gratuita, e tarefas de anúncio aparecem aleatoriamente no meio do caminho. Após a conclusão, eles podem receber uma oportunidade de loteria;',
  'turntable.rule3': '3. O direito final de interpretação desta atividade pertence à empresa',

  //国家/地区
  'region.title': 'País/Região',
  'region.choose.wallet': 'Por favor escolha uma carteira',
  'region.choose.country': 'Por favor seleccione',

  //视频页面支付挽留弹框
  'payhold.video.title': 'Presente exclusivo',
  'payhold.video.limit': 'Oferta limitada',

  //首页支付挽留弹框
  'payhold.home.title': 'Oferta especial',
  'payhold.home.until': 'Reduzido para',
  'payhold.home.buy': 'Comprar Agora',
  'payhold.home.limit': 'Fim do post',
  'payhole.home.bouns': 'Desbloqueie mais benefícios',

  // 活动页面
  'act.title1': 'Faça Dinheiro Online',
  'act.desc1': 'Compartilhe o drama curto e você receberá',
  'act.desc2': 'do valor recarregado',
  'act.title2': 'Inscreva-se agora',
  'act.tg.chat': 'TG chat',
  'act.tg.channel': 'TG channel',
  'act.tg.bot': 'TG bot',
};
