/**
 * lang: 印尼语
 * tips: 除公共部分，不同页面文案不要混着用
 */
export default {
  // 全局
  'common.toast.login': 'Silakan masuk',
  'common.toast.error.network': 'Masalah jaringan, silakan coba lagi',
  'common.toast.error.params': 'Kesalahan parameter',
  'common.btn.drama.watching': 'Mulai menonton',
  'common.list.finished': 'Tidak ada lagi',
  'common.btn.back.home': 'kembali',
  'common.text.text1': 'Geser ke atas untuk melihat lebih banyak',

  // 剧集相关
  'drama.episode': 'Episode {count}',
  'drama.watch.episode': 'Sampai episode {count}',
  'drama.total.episodes': 'Total {count} episode',
  'drama.selections': 'Pilih episode',

  // 登录
  'login.description': 'Masuk untuk pengalaman yang lebih baik',
  'login.tip.term': 'Melanjutkan berarti Anda setuju dengan ketentuan dan kebijakan privasi ini',
  'login.btn.google': 'Masuk dengan Google',
  'login.btn.logging': 'Masuk ...',

  // 首页
  'home.nav.record': 'Riwayat Menonton',
  'home.nav.logout': 'Keluar',
  'home.nav.vip': 'Pusat VIP',
  'home.btn.download': 'Unduh aplikasi',
  'home.btn.open': 'Buka aplikasi dan tonton video yang Anda minati',
  'home.tabs.tab1': 'Hit',
  'home.tabs.tab2': 'disarankan',
  'home.tabs.tab3': 'merekomendasikan',
  'home.tabs.tab4': 'disarankan',
  'home.tabs.tab5': 'tengah-menonton',
  'home.tabs.tab6': 'Pusat penggunaname',
  'home.tabs.tab7': 'catatan',
  'home.tabs.tab8': 'Kesejahteraan',

  // 观看历史
  'record.title': 'Riwayat Menonton',
  'record.title.share': 'Bagikan',
  'record.btn.share': 'Bagikan',
  'record.btn.playing': 'Bermain',
  'record.toast.copied': 'Berhasil disalin',
  'record.empty': 'Tidak ada data',

  // 分享页面
  'share.description': 'Buka Toktv untuk menonton video lengkap',
  'share.btn.open': 'Buka',
  'share.facebook.title': '',

  // 播放页面
  'video.play': 'main',
  'video.tips': 'Jika macet, buka APP',
  'video.select': 'Sebuah antologi',
  'video.nomore': 'Ini sudah akhir dari itu',
  'video.nolog': 'Anda belum menonton video apa pun.',
  'video.collect': 'Daftarku.',
  'video.havcollected': 'Kegemaran',
  'video.title1': 'Rilis bar',
  'video.title2': 'Paling populer',
  'video.title3': 'Lebih direkomendasikan',
  'video.guess.like': '',

  // 下载页面
  'download.btn': 'Unduh aplikasi',

  // 会员中心
  'play.txt.kb': 'Koin K',
  'play.txt.account': 'Akun',
  'play.txt.normal': 'Biasa',
  'play.txt.recharge': 'Isi Ulang',
  'play.txt.time': 'VIP akan berakhir pada {time}',
  'play.item.vip': 'Isi ulang anggota',
  'play.item.recharge': 'Isi ulang koin K',
  'play.item.details': 'Detail koin K',
  'play.item.gold': 'Koin emas bijaksana',
  'play.txt.details': 'Tidak Ada Detail Koin K',
  'play.txt.no_recharge': 'Tidak Ada Riwayat Isi Saldo',
  'play.txt.no_gold': 'Tidak ada catatan koin emas',
  'play.txt.purchase_time': 'Waktu Pembelian',
  'play.txt.order': 'Nomor Pesanan',
  'play.txt.order_err': 'Pemrosesan pesanan gagal, coba lagi',
  'play.txt.status_err': 'Pembayaran gagal',
  'play.txt.status_suc': 'Pembayaran berhasil',
  'play.txt.balance': 'Saldo koin K',
  'play.txt.balance1': 'Saldo',
  'play.txt.more': 'Tidak ada lagi',
  'play.txt.bonus': 'Bonus nilai tersimpan',
  'play.txt.tips': 'Koin K tidak mencukupi, harap isi ulang',
  'play.txt.wallet': 'dompet',
  'play.txt.desc': 'Nikmati semua bermain gratis',
  'play.txt.unlockall': '',
  'play.txt.limitpay': '',

  // 充值
  'play.txt.pay': 'Bayar Sekarang',
  'play.txt.rurchase': 'Pulihkan Pembelian',
  'play.txt.pack': 'Paket Hadiah VIP yang Luar Biasa',
  'play.get.coins': 'Dapatkan {coins} เหรียญ Koin K',
  'play.txt.notice': 'Catatan Penting',
  'play.txt.btn1': '',
  'play.txt.btn2': '',
  'play.txt.btn3': '',
  'play.txt.desc1':
    'Mata uang virtual dan hak kepemilikan yang dimiliki oleh akun adalah layanan jaringan dan tidak dapat dikembalikan setelah pembelian;',
  'play.txt.desc2':
    'Jika Anda membeli layanan langganan otomatis, untuk melindungi hak Anda, Anda perlu membuka aplikasi Google Play dan membatalkan langganan secara manual;',
  'play.txt.desc3':
    'Setelah pengisian saldo berhasil, tetapi saldo tidak berubah, silakan klik "Pulihkan Pembelian" untuk menyegarkan;',
  'play.txt.desc4': 'Jika ada pertanyaan lain, hubungi layanan pelanggan.',
  'play.txt.desc5': '',
  'play.txt.desc6': '',
  'play.txt.desc7': '',

  //设置
  'setting.title': 'mengatur',
  'setting.menu1': 'tukar bahasa',
  'setting.menu2': 'Perjanjian Pengguna',
  'setting.menu3': 'Polisi Privasi',
  'setting.menu4': 'hubungi kami',
  'setting.menu4.desc1': 'email pelanggan resmi',
  'setting.menu4.desc2': 'Kami akan menjawab email Anda segera setelah kami menerimanya',

  //消息
  'message.desc1': 'Permintaan gagal, coba lagi',
  'message.desc2': 'Nhẹ nhàng.',
  'message.desc3': 'Instalasi berhasil',

  //支付挽留弹框
  'pay.hold.text1': 'Tunjangan sosial',
  'pay.hold.text2': 'Waktu terbatas',
  'pay.hold.text3': 'Diskon.',
  'pay.hold.text4': 'Pemandangan bebas',
  'pay.hold.text5': 'Kualitas definisi tinggi',
  'pay.hold.text6': 'Status Noble',
  'pay.hold.text7': 'Bayar Sekarang',
  'pay.hold.text8': 'baru saja membeli',

  //福利界面
  'task.wallet': 'Dompetku.',
  'task.k': 'Koin K',
  'task.gold': 'Koin emas',
  'task.continuous': 'Periksa terus menerus: { day } hari',
  'task.day': 'Hari { day }th',
  'task.sign.btn': 'Daftar masuk',
  'task.day.text': 'Tugas harian',
  'task.newuser.text': 'Tugas Baru',
  'task.lockvideo.text': 'Hadiah untuk menonton drama',
  'task.btn.todo': 'lakukan',
  'task.btn.Receive': 'menerima',
  'task.lock.episodes': 'Lihat { episodes } episode drama',
  'task.signsuc.text': 'Pemeriksaan berhasil',
  'task.continuous.suc.desc':
    'Selesai pemeriksaan selama 7 hari berturut-turut dapat mendapatkan dua kali lipat hadiah',
  'task.again.get': 'Menerima koin { gold }',
  'task.get.title': 'Selamat untuk mendapatkan',
  'task.scanad.double': 'Mintalah dua kali lipat',
  'task.btn.ok': 'bagus',
  'task.btn.done': 'Selesai',
  'task.btn.comeback': 'Kembalilah besok.',
  'task.turntable.desc1': '100% Roda Beruntung',
  'task.turntable.desc2': 'Banyak koin emas menunggumu untuk menjemput',
  'task.ad': 'Menerima koin dengan menonton iklan',
  'task.ad.btn': 'ดmenonton',

  //大转盘界面
  'turntable.title': 'Roda Beruntung',
  'turntable.drawnum': '{ num } kesempatan yang tersisa hari ini',
  'turntable.drawbtn': 'Loteri gratis',
  'turntable.rule': 'Aturan aktivitas',
  'turntable.rule1':
    '1. Dengan berpartisipasi dalam aktivitas ini, seseorang setuju dengan aturan aktivitas dan dapat berpartisipasi dalam { total } gambar beruntung setiap hari;',
  'turntable.rule2':
    '2. Pengguna dapat menerima koin emas melalui loteri gratis, dan tugas iklan secara acak muncul di tengah jalan. Setelah selesai, mereka dapat menerima kesempatan loteri;',
  'turntable.rule3': '3. Hak interpretasi akhir dari aktivitas ini milik perusahaan',

  //国家/地区
  'region.title': 'Negara/Region',
  'region.choose.wallet': 'Silakan pilih dompet',
  'region.choose.country': 'Silakan pilih',
};
